import Stack from '../layout/Stack'
import Container from '../layout/Container'
import PageSegmentBackground from './PageSegmentBackground'
import PageSegmentHeading from './PageSegmentHeading'
import Testimonials from '../testimonials/Testimonials'

function TestimonialsSegment( {
  anchorId,
  title,
  subtitle,
  testimonials,
  additionalBottomPadding,
} ) {
  return (
    <PageSegmentBackground 
      anchorId={ anchorId }
      segmentType="testimonials"
      hasSectionTitle={ title || subtitle }
      additionalBottomPadding={ additionalBottomPadding }
    >
      <Container>
        <Stack
          $top={ 12 }
          $bottom={ 12 }
          $between={ 10 }
        >
          <PageSegmentHeading
            title={ title }
            subtitle={ subtitle }
          />
          <Testimonials
            testimonials={ testimonials }
          />
        </Stack>
      </Container>
    </PageSegmentBackground>
  )
}

export default TestimonialsSegment
