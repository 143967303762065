import styled from 'styled-components'
import Card from './Card'

function Cards( { alignment, cards } ) {
  return (
    <StyledCardsGrid>
      { cards.map( card => (
        <Card 
          key={ card.id }
          alignment={ alignment }
          card={ card }
        />
      ) ) }
    </StyledCardsGrid>
  )
}

const StyledCardsGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: var( --grid-gutter-offset );

  > * {
    margin: var( --grid-gutter-item );
  }
`

export default Cards
