import styled from 'styled-components'
import { Select } from '../Select/Select'

export type FilterGroupSelectProps = {
  label: string
  options: {
    label: string
    value: string
  }[]
  selected: string[]
  onChange: ( selected: string[] ) => void
}

export function FilterGroupSelect( {
  label,
  options,
  selected,
  onChange,
}: FilterGroupSelectProps ) {
  const selectedCount = selected.length ? `(${ selected.length })` : ''
  const displayValue = `${ label } ${ selectedCount }`

  return (
    <StyledSelect>
      <Select
        displayValue={ displayValue }
        options={ options }
        selected={ selected }
        onChange={ onChange }
      />
    </StyledSelect>
  )
}

const StyledSelect = styled.div`
  button {
    position: relative;
    display: flex;
    width: 100%;
    cursor: default;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    overflow: hidden;
    border: 1px solid ${ p => p.theme.colors.grey200 };
    border-radius: 100rem;
    background: transparent;
    padding: 8px 12px;
    text-align: left;
    font-size: 14px;
    outline: none;

    &:focus-within {
      border-color: ${ p => p.theme.colors.green400 };
    }

    > span {
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    > div {
      flex-shrink: 0;
    }
  }
`
