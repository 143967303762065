import Stack from '../layout/Stack'
import Container from '../layout/Container'
import PageSegmentBackground from './PageSegmentBackground'
import PageSegmentHeading from './PageSegmentHeading'
import PageSegmentBodyText from './PageSegmentBodyText'
import ProcessStepsSimple from '../processStepsSimple/ProcessStepsSimple'
import ButtonGroup from '../ButtonGroup'

export default function ProcessStepsSimpleSegment( {
  anchorId,
  title,
  subtitle,
  bodyText,
  button,
  processStepsSimple,
  additionalBottomPadding,
} ) {
  return (
    <PageSegmentBackground 
      anchorId={ anchorId }
      color="ivory"
      segmentType="processSteps"
      hasSectionTitle={ title || subtitle }
      additionalBottomPadding={ additionalBottomPadding }
    >
      <Container>
        <Stack
          $top={ 12 }
          $bottom={ 12 }
          $between={ 10 }
        >
          <PageSegmentHeading
            title={ title }
            subtitle={ subtitle }
          />
          <div>
            <ProcessStepsSimple
              steps={ processStepsSimple }
            />
          </div>
          <PageSegmentBodyText
            html={ bodyText }
            bodyStyle="small"
          />
          { button && (
            <ButtonGroup
              buttons={ [
                {
                  id: 1,
                  label: button.text,
                  href: button.url,
                  primary: true,
                  color: 'red',
                  openInNewTab: button.target === '_blank',
                },
              ] }
            />
          ) }
        </Stack>
      </Container>
    </PageSegmentBackground>
  )
}
