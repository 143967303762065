import Stack from '../layout/Stack'
import PageSegmentHeading from './PageSegmentHeading'
import InfoPoints from '../infoPoints/InfoPoints'
import Container from '../layout/Container'
import PageSegmentBackground from './PageSegmentBackground'

function InfoPointsSegment( {
  anchorId,
  title,
  subtitle,
  alignment,
  infoPoints,
  additionalBottomPadding,
} ) {
  return (
    <PageSegmentBackground 
      anchorId={ anchorId }
      segmentType="infoPoints"
      hasSectionTitle={ title || subtitle }
      additionalBottomPadding={ additionalBottomPadding }
    >
      <Container>
        <Stack
          $top={ 12 }
          $bottom={ 12 }
          $between={ 10 }
        >
          <PageSegmentHeading
            title={ title }
            subtitle={ subtitle }
          />
          <InfoPoints
            infoPoints={ infoPoints }
            alignment={ alignment }
          />
        </Stack>
      </Container>
    </PageSegmentBackground>
  )
}

export default InfoPointsSegment
