import styled from 'styled-components'
import Image from './Image'

function PartnerLogo( {
  label,
  logos,
} ) {
  return (
    <div>
      { label && (
        <StyledPartnershipTitle>
          { label }
        </StyledPartnershipTitle>
      ) }
      <StyledPartnerLogos>
        { logos?.map( image => (
          <StyledPartnerLogo  
            key={ image.id }
          >
            <Image
              src={ image.url }
              alt={ image.alt }
              width={ image.width }
              height={ image.height }
              dateModified={ image.dateModified }
            />
          </StyledPartnerLogo>
        ) ) }
      </StyledPartnerLogos>
    </div>
  )
}

const StyledPartnershipTitle = styled.span`
  ${ p => p.theme.typo.smallMeta }
  color: ${ p => p.theme.colors.grey400 };
`

const StyledPartnerLogos = styled.div`
  display: flex;
  align-items: center;
  margin: calc( -2 * var( --stack-basis ) );
  margin-top: var( --stack-basis );
`

const StyledPartnerLogo = styled.div`
  flex-grow: 1;
  max-width: 120px;
  max-height: 60px;
  padding-left: calc( 2 * var( --stack-basis ) );
  padding-right: calc( 2 * var( --stack-basis ) );
  margin-top: calc( 2 * var( --stack-basis ) );
  margin-bottom: calc( 2 * var( --stack-basis ) );

  & + div {
    border-left: 1px solid ${ p => p.theme.colors.grey400 };
  }
`

export default PartnerLogo
