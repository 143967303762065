import { IntlShape } from 'react-intl'

export default function swissCantons( intl: IntlShape ) {
  return {
    AG: intl.formatMessage( {
      description: 'Swiss Canton name for state code: AG',
      defaultMessage: 'Aargau',
      id: 'w+vERP',
    } ),
    AI: intl.formatMessage( {
      description: 'Swiss Canton name for state code: AI',
      defaultMessage: 'Appenzell Inner-Rhodes',
      id: 'ZQWkJ9',
    } ),
    AR: intl.formatMessage( {
      description: 'Swiss Canton name for state code: AR',
      defaultMessage: 'Appenzell Outer-Rhodes',
      id: '6fRR0B',
    } ),
    BE: intl.formatMessage( {
      description: 'Swiss Canton name for state code: BE',
      defaultMessage: 'Bern',
      id: 'iPAnLE',
    } ),
    BL: intl.formatMessage( {
      description: 'Swiss Canton name for state code: BL',
      defaultMessage: 'Basel District',
      id: 'LGpzov',
    } ),
    BS: intl.formatMessage( {
      description: 'Swiss Canton name for state code: BS',
      defaultMessage: 'Basel',
      id: 'gtiHtC',
    } ),
    FR: intl.formatMessage( {
      description: 'Swiss Canton name for state code: FR',
      defaultMessage: 'Fribourg',
      id: '3CyOuX',
    } ),
    GE: intl.formatMessage( {
      description: 'Swiss Canton name for state code: GE',
      defaultMessage: 'Geneva',
      id: 'BML85d',
    } ),
    GL: intl.formatMessage( {
      description: 'Swiss Canton name for state code: GL',
      defaultMessage: 'Glarus',
      id: 'iIDcto',
    } ),
    GR: intl.formatMessage( {
      description: 'Swiss Canton name for state code: GR',
      defaultMessage: 'Grisons',
      id: 'RxH0Zp',
    } ),
    JU: intl.formatMessage( {
      description: 'Swiss Canton name for state code: JU',
      defaultMessage: 'Jura',
      id: 'JchSgw',
    } ),
    LU: intl.formatMessage( {
      description: 'Swiss Canton name for state code: LU',
      defaultMessage: 'Lucerne',
      id: '5vG1Zi',
    } ),
    NE: intl.formatMessage( {
      description: 'Swiss Canton name for state code: NE',
      defaultMessage: 'Neuchâtel',
      id: '+hh+VE',
    } ),
    NW: intl.formatMessage( {
      description: 'Swiss Canton name for state code: NW',
      defaultMessage: 'Nidwalden',
      id: 'EQ/XxL',
    } ),
    OW: intl.formatMessage( {
      description: 'Swiss Canton name for state code: OW',
      defaultMessage: 'Obwalden',
      id: 'ssOh8n',
    } ),
    SG: intl.formatMessage( {
      description: 'Swiss Canton name for state code: SG',
      defaultMessage: 'St. Gallen',
      id: 'XOCDU1',
    } ),
    SH: intl.formatMessage( {
      description: 'Swiss Canton name for state code: SH',
      defaultMessage: 'Schaffhausen',
      id: 'wQJ8/A',
    } ),
    SO: intl.formatMessage( {
      description: 'Swiss Canton name for state code: SO',
      defaultMessage: 'Solothurn',
      id: 'kcqF2C',
    } ),
    SZ: intl.formatMessage( {
      description: 'Swiss Canton name for state code: SZ',
      defaultMessage: 'Schwyz',
      id: 'DXJv2Y',
    } ),
    TG: intl.formatMessage( {
      description: 'Swiss Canton name for state code: TG',
      defaultMessage: 'Thurgau',
      id: 'MIYCJc',
    } ),
    TI: intl.formatMessage( {
      description: 'Swiss Canton name for state code: TI',
      defaultMessage: 'Ticino',
      id: 'YyoBcv',
    } ),
    UR: intl.formatMessage( {
      description: 'Swiss Canton name for state code: UR',
      defaultMessage: 'Uri',
      id: 'KWSZCn',
    } ),
    VD: intl.formatMessage( {
      description: 'Swiss Canton name for state code: VD',
      defaultMessage: 'Vaud',
      id: 'h4gb19',
    } ),
    VS: intl.formatMessage( {
      description: 'Swiss Canton name for state code: VS',
      defaultMessage: 'Valais',
      id: 'FScS0U',
    } ),
    ZG: intl.formatMessage( {
      description: 'Swiss Canton name for state code: ZG',
      defaultMessage: 'Zug',
      id: 'kB1Pu4',
    } ),
    ZH: intl.formatMessage( {
      description: 'Swiss Canton name for state code: ZH',
      defaultMessage: 'Zurich',
      id: 'z+bbkF',
    } ),
  }
}
