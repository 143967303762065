import { getColor } from '../../../styles/colors'

function HouseInHand( { color = 'green700' } ) {
  const houseColor = getColor( color )
  const handColor = getColor( 'black' )

  return (
    <svg 
      width="162"
      height="151"
      viewBox="0 0 162 151"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path 
        d="M53.9199 13.5684L65.8096 25.4297"
        stroke={ handColor }
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path 
        d="M127.591 13.5684L115.701 25.4297"
        stroke={ handColor }
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path 
        d="M90.7568 17.8048V1.03149"
        stroke={ handColor }
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path 
        d="M59.0154 89.4508L31.469 72.0219C30.5103 71.4155 29.4253 71.0359 28.2969 70.9122C27.1686 70.7885 26.0269 70.9239 24.959 71.308C23.8912 71.6922 22.9256 72.3149 22.1361 73.1286C21.3467 73.9423 20.7542 74.9253 20.4041 76.0025C19.8249 77.794 19.9497 79.738 20.7531 81.4411C21.5565 83.1443 22.95 84.723 24.7029 85.4196"
        stroke={ handColor }
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path 
        d="M48.7827 82.9826L38.7515 76.6252C37.8708 76.0679 37.1252 75.3221 36.5688 74.442C36.0125 73.5618 35.6593 72.5693 35.5347 71.5362C35.4102 70.5031 35.5174 69.4553 35.8487 68.4686C36.18 67.4819 36.7271 66.5811 37.4503 65.8313C38.7259 64.5087 40.47 63.737 42.3089 63.6814C44.1479 63.6258 46.0784 64.5133 47.2892 65.5339L58.0838 73.245"
        stroke={ handColor }
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path 
        d="M99.6783 83.3856L67.4597 103.281L58.0742 99.1665V51.5514L90.954 27.2755L123.834 51.5514V86.1293L114.736 82.5962C112.314 81.6544 109.718 81.2377 107.122 81.3738C104.526 81.5099 101.988 82.1957 99.6783 83.3856Z"
        fill={ houseColor }
      />
      <path 
        d="M160.038 149.889L132.099 134.045C128.737 132.139 125.027 130.923 121.187 130.469C117.347 130.016 113.455 130.334 109.74 131.404L81.3522 139.582C78.4293 140.426 75.3541 140.607 72.3519 140.112C69.3497 139.617 66.4965 138.459 64.0012 136.722L5.69521 96.156C4.62652 95.4105 3.73845 94.436 3.096 93.3039C2.45356 92.1717 2.073 90.9106 1.98218 89.6128C1.89136 88.315 2.09257 87.0134 2.57109 85.8032C3.04962 84.593 3.79334 83.5048 4.74783 82.6184C5.95958 81.4966 7.46519 80.7403 9.09017 80.4372C10.7151 80.1342 12.3929 80.2967 13.9291 80.906L66.2582 102.894M160.038 104.334L119.198 84.4254C115.554 82.6492 111.524 81.8037 107.471 81.9648C103.419 82.1259 99.4696 83.2885 95.9785 85.3482L66.2585 102.883C65.2736 103.465 64.4209 104.245 63.7546 105.173C63.0883 106.101 62.623 107.158 62.3884 108.276C62.1538 109.393 62.1551 110.547 62.392 111.664C62.6289 112.781 63.0964 113.837 63.7647 114.764C64.8322 116.243 66.362 117.326 68.1136 117.843C69.8653 118.36 71.7395 118.283 73.4419 117.622L99.6343 107.461"
        stroke={ handColor }
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>

  )
}

export default HouseInHand
