import styled from 'styled-components'
import Image from './Image'

function MaxAreaImage( {
  src,
  alt,
  width,
  height,
  maxArea,
  maxWidth = null,
  maxHeight = null,
  dateModified = null,
} ) {
  const aspectRatio = height / width

  const calculatedMaxWidth = maxWidth 
    ? Math.min( Math.sqrt( maxArea / aspectRatio ), maxWidth ) 
    : Math.sqrt( maxArea / aspectRatio )

  const calculatedMaxHeight = maxHeight 
    ? Math.min( calculatedMaxWidth * aspectRatio, maxHeight ) 
    : calculatedMaxWidth * aspectRatio

  return (
    <StyledMaxAreaImage
      $maxWidth={ Math.floor( calculatedMaxWidth ) }
      $maxHeight={ Math.floor( calculatedMaxHeight ) }
    >
      <Image 
        src={ src }
        alt={ alt }
        width={ width }
        height={ height }
        dateModified={ dateModified }
      />
    </StyledMaxAreaImage>
  )
}

const StyledMaxAreaImage = styled.div<{
  $maxHeight: number,
  $maxWidth: number,
}>`
  width: ${ p => p.$maxWidth }px;
  height: ${ p => p.$maxHeight }px;
  max-width: 100%;
  max-height: 100%;
`

export default MaxAreaImage
