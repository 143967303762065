import styled from 'styled-components'
import Link from './Link'
import MaxAreaImage from './MaxAreaImage'

function LogoGrid( { linkedLogos } ) {
  return (
    <StyledLogoGrid>
      { linkedLogos.map( logo => (
        <Logo
          key={ logo.id }
          url={ logo.logoLink }
          image={ logo.logoImage[ 0 ] }
        />
      ) )}
    </StyledLogoGrid>
  )
}

function Logo( { image, url } ) {
  return (
    <StyledLogo>
      { url && (
        <Link href={ url }>
          <LogoImage image={ image } />
        </Link>
      ) }
      { !url && <LogoImage image={ image } /> }
    </StyledLogo>
  )
}

function LogoImage( { image } ) {
  return (
    <StyledLogoImage>
      <MaxAreaImage
        maxArea={ 5000 }
        src={ image.url }
        alt={ image.alt }
        width={ image.width }
        height={ image.height }
        dateModified={ image.dateModified }
      />
    </StyledLogoImage>
  )
}

const StyledLogoGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: calc( 2 * var( --grid-gutter ) );
`

const StyledLogo = styled.div`
  max-width: 130px;
`

const StyledLogoImage = styled.div`
  
`

export default LogoGrid
