import { useRouter } from 'next/router'
import styled from 'styled-components'
import Stack from '../layout/Stack'
import PageSegmentHeading from './PageSegmentHeading'
import Container from '../layout/Container'
import PageSegmentBackground from './PageSegmentBackground'
import PropertyListings from '../propertyListings/PropertyListings'
import ButtonGroup from '../ButtonGroup'
import PropertyListingsFilterGroup from '../ui/PropertyListingsFilterGroup/PropertyListingsFilterGroup'

function PropertyListingsSegment( {
  anchorId,
  title,
  subtitle,
  additionalBottomPadding,
  buttonLabel,
  buttonLink,
  company,
  related,
  numberOfProperties,
  showCountdown,
  propertyCardStyle,
  carouselForSmallScreens,
  showLoadMoreButton,
  showFilterControls,
} ) {
  const { asPath, query, push } = useRouter()
  const stateCodes = Array.isArray( query.stateCodes ) ? query.stateCodes : query.stateCodes?.split( ',' ) || []
  const sectionHeadingId = `${ anchorId }-heading`

  const [filterControls, filterSummary] = PropertyListingsFilterGroup( {
    selected: {
      stateCodes,
    },
    onChange: ( { stateCodes: newSelectedStateCodes } ) => {  
      const pathname = asPath.split( '?' )[ 0 ]
      
      if ( !newSelectedStateCodes?.length ) {
        push( pathname, undefined, { scroll: false } )
        return
      }

      const urlSearchParams = new URLSearchParams()   

      newSelectedStateCodes.forEach( stateCode => {
        urlSearchParams.append( 'stateCodes', stateCode )
      } )

      push( `${ pathname }?${ urlSearchParams.toString() }`, undefined, { scroll: false } )
    },
  } )

  return (
    <PageSegmentBackground 
      anchorId={ anchorId }
      segmentType={ propertyCardStyle === 'large' ? 'propertyListingsLarge' : 'propertyListings' }
      hasSectionTitle={ title || subtitle }
      additionalBottomPadding={ additionalBottomPadding }
      color="ivory"
      hideOverflow
    >
      <section aria-labelledby={ sectionHeadingId }>
        <Container>
          <Stack
            $top={ 12 }
            $bottom={ 12 }
            $between={ 10 }
          >
            <PageSegmentHeading
              id={ sectionHeadingId }
              title={ title }
              subtitle={ subtitle }
            />
            { showFilterControls && (
              <StyledFilters>
                { filterControls }
                { filterSummary }
              </StyledFilters>
            ) }
            <PropertyListings
              company={ company }
              related={ related }
              numberOfProperties={ numberOfProperties }
              propertyCardStyle={ propertyCardStyle }
              carouselForSmallScreens={ carouselForSmallScreens }
              showCountdown={ showCountdown }
              showLoadMoreButton={ showLoadMoreButton }
            />
            { buttonLabel && buttonLink && (
              <ButtonGroup 
                buttons={ [
                  {
                    id: 1,
                    label: buttonLabel,
                    href: buttonLink,
                    primary: true,
                  },
                ] }
              />
            ) }
          </Stack>
        </Container>
      </section>
    </PageSegmentBackground>
  )
}

export default PropertyListingsSegment

const StyledFilters = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
`
