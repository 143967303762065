import Stack from '../layout/Stack'
import Container from '../layout/Container'
import PageSegmentBackground from './PageSegmentBackground'
import PageSegmentHeading from './PageSegmentHeading'
import ContactCards from '../contact/ContactCards'

function ContactSegment( {
  anchorId,
  title,
  subtitle,
  contactCards,
  additionalBottomPadding,
} ) {
  return (
    <PageSegmentBackground 
      anchorId={ anchorId }
      color="green200"
      segmentType="contact"
      hasSectionTitle={ title || subtitle }
      additionalBottomPadding={ additionalBottomPadding }
    >
      <Container>
        <Stack
          $top={ 12 }
          $bottom={ 12 }
          $between={ 10 }
        >
          <PageSegmentHeading
            title={ title }
            subtitle={ subtitle }
          />
          <ContactCards
            contactCards={ contactCards }
          />
        </Stack>
      </Container>
    </PageSegmentBackground>
  )
}

export default ContactSegment
