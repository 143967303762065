import styled from 'styled-components'
import Button from '../Button'
import Image from '../Image'
import Stack from '../layout/Stack'
import PageSegmentBackground from './PageSegmentBackground'

export default function StorySegment( {
  anchorId,
  label,
  title,
  subtitle,
  buttonLabel,
  buttonLink,
  buttonTarget,
  image,
  additionalBottomPadding,
} ) {
  return (
    <PageSegmentBackground
      anchorId={ anchorId }
      segmentType="story"
      color="#f0f0f0"
      hasSectionTitle={ false }
      additionalBottomPadding={ additionalBottomPadding }
    >
      <StyledStory>
        <StyledStoryImage>
          <Image
            alt={ image.alt }
            src={ image.url }
            layout="fill"
            objectFit="cover"
            dateModified={ image.dateModified }
          />
        </StyledStoryImage>
        <StyledStoryContent>
          <Stack
            $top={ 10 }
            $bottom={ 10 }
            $between={ 3 }
          >
            <h2>{ label }</h2>
            <h3>{ title }</h3>
            <p>{ subtitle }</p>
            <Button
              href={ buttonLink }
              target={ buttonTarget }
            >
              { buttonLabel }
            </Button>
          </Stack>
        </StyledStoryContent>
      </StyledStory>
    </PageSegmentBackground>
  )
}

const StyledStoryImage = styled.div`
  position: relative;
  flex: 0 0 max( 42%, calc( 50% - ( 10 * var( --stack-basis ) ) ) );
  min-height: 200px;
`

export const StyledStoryContent = styled.div`
  margin-left: min( 8%, calc( 10 * var( --stack-basis ) ) );
  margin-right: var( --container-margin-width );
  max-width: 31rem;

  h2 {
    ${ p => p.theme.typo.mono };
    font-size: ${ p => p.theme.typo.sizes.dropped };
    font-weight: 400;
  }

  h3 {
    font-size: ${ p => p.theme.typo.sizes.smallHero };
    line-height: 1.1;
    font-weight: 600;
  }

  p {
    font-size: ${ p => p.theme.typo.sizes.large };
    line-height: 1.2;
  }

  @media ( max-width: 500px ) {
    h3 {
      font-size: ${ p => p.theme.typo.sizes.veryLarge };
      /* line-height: 1.2; */
    }

    p {
      font-size: ${ p => p.theme.typo.sizes.medium };
      line-height: 1.35;
    }
  }
`

const StyledStory = styled.div`
  display: flex;

  @media ( max-width: 750px ) {
    flex-wrap: wrap;

    ${ StyledStoryImage } {
      flex-basis: 100vw;
    }

    ${ StyledStoryContent } {
      margin-left: var( --container-margin-width );
    }
  }
`
