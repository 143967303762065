import React from 'react'
import styled from 'styled-components'

function Map( { location } ) {
  return (
    <StyledMap>
      <iframe title={ location.address } src={ `https://maps.google.com/maps?q=${ location.lat },${ location.lng }&t=&z=18&ie=UTF8&iwloc=&output=embed` } />
    </StyledMap>
  )
}

const StyledMap = styled.div`
  border-radius: 12px;
  aspect-ratio: 650/430;
  position: relative;
  border: 1px solid ${ p => p.theme.colors.grey400 };
  overflow: hidden;

  iframe {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
`

export default Map
