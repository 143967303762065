import Stack from '../layout/Stack'
import Container from '../layout/Container'
import PageSegmentBackground from './PageSegmentBackground'
import PageSegmentHeading from './PageSegmentHeading'
import Logos from '../logos/Logos'

function LogosSegment( {
  anchorId,
  title,
  subtitle,
  logos,
  additionalBottomPadding,
} ) {
  return (
    <PageSegmentBackground 
      anchorId={ anchorId }
      segmentType="logos"
      hasSectionTitle={ title || subtitle }
      additionalBottomPadding={ additionalBottomPadding }
    >
      <Container>
        <Stack
          $top={ ( title || subtitle ) ? 12 : 0 }
          $bottom={ 12 }
          $between={ ( title || subtitle ) ? 7 : 10 }
        >
          <PageSegmentHeading
            title={ title }
            subtitle={ subtitle }
          />
          <Logos
            logos={ logos }
          />
        </Stack>
      </Container>
    </PageSegmentBackground>
  )
}

export default LogosSegment
