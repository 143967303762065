import { getColor } from '../../../styles/colors'

export default function MagnifyingGlass( { color = 'green700' } ) {
  const houseColor = getColor( color )
  const magnifyingGlassColor = getColor( 'black' )

  return (
    <svg 
      width="144"
      height="144"
      viewBox="0 0 144 144"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path 
        d="M50.9131 86.4219L42.5084 94.8265L48.8751 101.193L57.2797 92.7885"
        stroke={ magnifyingGlassColor }
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path 
        d="M53.8525 96.2452C52.7899 95.3299 51.7534 94.3684 50.7457 93.3607C49.6103 92.2253 48.5336 91.0534 47.5156 89.8488L50.7457 86.6187L53.929 89.802L57.1123 92.9853L53.8525 96.2452Z"
        fill={ magnifyingGlassColor }
        stroke={ magnifyingGlassColor }
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <circle 
        cx="90.7417"
        cy="53.3357"
        r="51.8034"
        stroke={ magnifyingGlassColor }
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <circle 
        cx="90.7418"
        cy="53.3358"
        r="33.9642"
        transform="rotate(45 90.7418 53.3358)"
        stroke={ magnifyingGlassColor }
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <rect 
        x="36.5592"
        y="88.8271"
        width="25.9017"
        height="49.645"
        transform="rotate(45 36.5592 88.8271)"
        stroke={ magnifyingGlassColor }
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path 
        d="M108.899 68.0305C108.899 69.135 108.003 70.0305 106.899 70.0305H74.5848C73.4803 70.0305 72.5848 69.135 72.5848 68.0305V44.5174C72.5848 43.8854 72.8835 43.2906 73.3905 42.9132L89.5474 30.8839C90.2562 30.3561 91.2274 30.3561 91.9362 30.8839L108.093 42.9132C108.6 43.2906 108.899 43.8854 108.899 44.5174V68.0305Z"
        fill={ houseColor }
      />
    </svg>
  )
}
