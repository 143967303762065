import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'
import { useRouter } from 'next/router'
import useMediaQuery from '@/hooks/useMediaQuery'
import { useHubAPIRequestInfinite } from '../../hubApi/useHubAPIRequest'
import { useCraft } from '../../lib/craft'
import Stack from '../layout/Stack'
import PropertyListingLargeCard from '../propertyListingsLarge/PropertyListingLargeCard'
import PropertyCard from './PropertyCard'
import Carousel from '../Carousel'
import ButtonGroup from '../ButtonGroup'
import ParkScene from '../icons/brixel/ParkScene'

function endpointPaginator( 
  endpoint: string, 
  limit: number, 
) {
  return function getPaginatedEndpoint( 
    pageIndex: number, 
    previousPageData: string | any[], 
  ) {
    if ( previousPageData && !previousPageData.length ) {
      return null
    }

    const [ep, searchParams] = endpoint.split( '?' )
    const params = searchParams ? `&${ searchParams }` : ''
    return `${ ep }?limit=${ limit }&offset=${ pageIndex * limit }${ params }`
  }
}

export default function PropertyListings( { 
  company, 
  related,
  numberOfProperties,
  propertyCardStyle,
  carouselForSmallScreens,
  showCountdown,
  showLoadMoreButton,
} ) {
  const craftData = useCraft()
  const isSmallScreen = useMediaQuery( '( max-width: 768px )' )
  const shouldUseCarousel = isSmallScreen && carouselForSmallScreens
  const companyStub = company || craftData?.pageProps?.partnerSlug || 'brixel_zurich'
  const defaultNumberOfProperties = propertyCardStyle === 'large' ? 3 : 6

  const resultsPerPage = numberOfProperties && numberOfProperties > 0 
    ? numberOfProperties 
    : defaultNumberOfProperties

  const { query } = useRouter()
  const searchParams = new URLSearchParams()
  
  Object.entries( query ).forEach( ( [key, value] ) => {
    if ( key === 'stateCodes' ) {
      if ( typeof value === 'string' ) {
        searchParams.append( key, value )
      }
      else {
        value.forEach( v => searchParams.append( key, v ) )
      }
    }
  } )

  const relatedEndpoint = ['related', 'others', 'mixed', 'portfolio'].includes( related ) ? related : 'others'
  const endpoint = `/api/company/stub/${ companyStub }/portfolio/${ relatedEndpoint }?${ searchParams.toString() }`

  const {
    data, error, size, setSize, 
  } = useHubAPIRequestInfinite( 
    endpointPaginator( endpoint, resultsPerPage ), 
    { initialSize: 1 }, 
  )

  if ( !data && !error ) {
    return null
  }

  const properties = data?.flat()
  properties.length = Math.min( size * resultsPerPage, properties.length )

  if ( 
    error 
    || properties.length === 0
    || properties[ 0 ]?.status === 'Data is currently unavailable' 
  ) {
    return (
      <StyledEmptyState>
        <div>
          <p>
            <FormattedMessage
              defaultMessage="We can't find any properties meeting your search criteria."
              id="mHZeeW"
              description="Message shown when no properties are found matching the search criteria"
            />
          </p>
        </div>
        <ParkScene />
      </StyledEmptyState>
    )
  }

  if ( propertyCardStyle === 'large' ) {
    return (
      <div>
        <Stack
          $between={ 2 }
          $bottom={ showLoadMoreButton && properties.length >= ( resultsPerPage * size ) ? 6 : 0 }
        >
          { properties.map( property => (
            <div key={ property.id }>
              <PropertyListingLargeCard 
                property={ property } 
                showCountdown={ showCountdown }
              />
            </div>
          ) ) }
        </Stack>
        { showLoadMoreButton && properties.length >= ( resultsPerPage * size ) && (
          <ButtonGroup
            buttons={ [
              {
                id: 'load-more',
                onClick: () => setSize( s => s + 1 ),
                label: (
                  <FormattedMessage
                    defaultMessage="Load more"
                    id="0YlyNR"
                    description="Load more button label"
                  />
                ),
                href: null,
              },
            ] }
          />
        ) }
      </div>
    )
  }

  if ( shouldUseCarousel ) {
    return (
      <Carousel>
        { properties.map( property => ( 
          <li key={ property.id }>
            <PropertyCard
              property={ property }
              showCountdown={ showCountdown }
            />
          </li>
        ) ) }
      </Carousel>
    )
  }

  return (
    <Stack $between={ 6 }>
      <StyledPropertyListings>
        { properties.map( property => (
          <PropertyCard
            key={ property.id }
            property={ property }
            showCountdown={ showCountdown }
          />
        ) ) }
      </StyledPropertyListings>
      { showLoadMoreButton && properties.length >= ( resultsPerPage * size ) && (
        <ButtonGroup
          buttons={ [
            {
              id: 'load-more',
              onClick: () => setSize( s => s + 1 ),
              label: (
                <FormattedMessage
                  defaultMessage="Load more"
                  id="0YlyNR"
                  description="Load more button label"
                />
              ),
              href: null,
            },
          ] }
        />
      ) }
    </Stack>
  )
}

const StyledPropertyListings = styled.div`
  --min-grid-item-width: 320px;
  display: grid;
  grid-template-columns: repeat( auto-fill, minmax( min( 100%, var( --min-grid-item-width ) ), 1fr ) );
  gap: calc( 3 * var( --stack-basis ) );

  > * {
    max-width: 100%;
  }

  @media ( min-width: 1200px ) {
    --min-grid-item-width: 380px;
  }
`

const StyledEmptyState = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: space-between;
  align-items: center;
  font-size: ${ p => p.theme.typo.sizes.large };

  > div {
    max-width: 20em;
  }
`
