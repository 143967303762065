import React from 'react'
import styled from 'styled-components'

function DefinitionList( { items } ) {
  return (
    <StyledList>
      {items.map( ( { key, term, data } ) => (
        <React.Fragment key={ key }>
          <dt>{term}</dt>
          <dd>{data}</dd>
        </React.Fragment>
      ) )}
    </StyledList>
  )
}

const StyledList = styled.dl`
  dd {
    margin: 0;
  }

  dt {
    color: ${ p => p.theme.colors.green400 };
  }

  dt:not(:first-child) {
    margin-top: 18px;
  }
`

export default DefinitionList
