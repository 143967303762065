import React from 'react'
import styled from 'styled-components'
import { wrapWithPTags } from '../../lib/strings'

function Highlight( {
  eyebrow,
  title,
  description,
  image,
  caption,
  alignment = 'row',
} : {
  eyebrow?: string
  title: string
  description?: string
  caption?: React.ReactNode
  image: React.ReactNode
  alignment: 'row' | 'row-reverse'
} ) {
  return (
    <StyledHighlight $alignment={ alignment }>
      <StyledImage>
        {image}
      </StyledImage>
      <StyledCaption>
        {eyebrow && <span>{eyebrow}</span>}
        {title && <h3>{title}</h3>}
        {description && (
          // eslint-disable-next-line react/no-danger
          <div dangerouslySetInnerHTML={ { __html: wrapWithPTags( description ) } } />
        )}
        {caption}
      </StyledCaption>
    </StyledHighlight>
  )
}

const StyledHighlight = styled.figure<{ $alignment: 'row' | 'row-reverse' }>`
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  column-gap: 73px;
  row-gap: 24px;
  flex-direction: ${ p => p.$alignment };
`
const StyledCaption = styled.figcaption`
  flex: 1 0 calc(40% - 73px);
  min-width: 305px;

  > span {
    ${ p => p.theme.typo.mono }
    font-size: ${ p => p.theme.typo.sizes.small };
    color: ${ p => p.theme.getColor( 'green400' ) };
  }
`

const StyledImage = styled.div`
  flex: 1 0 calc(60% - 73px);
`

export default Highlight
