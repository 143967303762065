import Stack from '../layout/Stack'
import PageSegmentHeading from './PageSegmentHeading'
import Container from '../layout/Container'
import PageSegmentBackground from './PageSegmentBackground'
import ThreeDeeTourHighlight from '../highlights/ThreeDeeTourHighlight'
import ImagesHighlight from '../highlights/ImagesHighlight'

function HighlightsSegment( {
  anchorId,
  title,
  highlights,
} ) {
  return (
    <PageSegmentBackground
      anchorId={ anchorId }
      color="ivory"
      segmentType="highlights"
      hasSectionTitle={ title }
    >
      <Container>
        <Stack
          $top={ 12 }
          $bottom={ 12 }
          $between={ 10 }
        >
          <PageSegmentHeading
            title={ title }
            align="left"
          />
          {highlights.map( ( highlight, i ) => {
            const alignment = i % 2 === 0 ? 'row' : 'row-reverse'
            if ( highlight.typeHandle === 'threeDeeTour' ) {
              return (
                <ThreeDeeTourHighlight
                  key={ highlight.id }
                  { ...{ alignment } }
                  eyebrow={ highlight.highlightEyebrow }
                  buttonText={ highlight.highlightButtonText }
                  title={ highlight.highlightTitle }
                  description={ highlight.highlightDescription }
                  image={ highlight.highlightPreviewImage?.[ 0 ] }
                  tourURL={ highlight.highlightTourUrl }
                />
              )
            }
            if ( highlight.typeHandle === 'images' ) {
              return (
                <ImagesHighlight
                  key={ highlight.id }
                  { ...{ alignment } }
                  eyebrow={ highlight.highlightEyebrow }
                  title={ highlight.highlightTitle }
                  description={ highlight.highlightDescription }
                  images={ highlight.highlightImages }
                />
              )
            }
            return null
          } )}
        </Stack>
      </Container>
    </PageSegmentBackground>
  )
}

export default HighlightsSegment
