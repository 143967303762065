import styled from 'styled-components'
import { useEffect, useRef, useState } from 'react'
import useModalTraits from '@/hooks/useModalTraits'
import Button from '../Button'
import Image from '../Image'
import Highlight from './Highlight'
import VideoPlayIcon from '../icons/VideoPlayIcon'

function ThreeDeeTourHighlight( {
  title, description, eyebrow, image, buttonText, tourURL, alignment,
} ) {
  const [tour, setTour] = useState( false )
  const launchTour = () => setTour( true )
  const closeTour = () => setTour( false )

  const tourRef = useRef()

  useModalTraits( {
    containerRef: tourRef,
    isOpen: tour,
    close: closeTour,
    preventBodyScroll: true,
  } )

  useEffect( () => {
    const handler = ( e: KeyboardEvent ) => {
      if ( e.key === 'Escape' ) {
        closeTour()
      }
    }
    document.addEventListener( 'keyup', handler )
    return () => document.removeEventListener( 'keyup', handler )
  } )

  return (
    <>
      <Highlight
        { ...{
          title, description, eyebrow, alignment, 
        } }
        image={ image && (
        <StyledCoverImage>
          <button
            type="button"
            onClick={ launchTour }
          >
            <Image
              src={ image.url }
              alt={ image.alt }
              dateModified={ image.dateModified }
              layout="fill"
              objectFit="cover"
            />
            <VideoPlayIcon />
          </button>
        </StyledCoverImage>
        ) }
        caption={ tourURL && (
          <Button onClick={ launchTour }>{buttonText}</Button>
        ) }
      />
      {tour && (
        <StyledTourEmbed ref={ tourRef }>
          <iframe src={ tourURL } title={ title } />
          <Button onClick={ closeTour }>Close</Button>
        </StyledTourEmbed>
      )}
    </>
  )
}

const StyledTourEmbed = styled.div`
  position: fixed;
  background-color: #222; // matterport bg color
  inset: 0;
  z-index: 99;
  iframe {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
  }
  button {
    position: absolute;
    top: 30px;
    right: 30px;
    z-idnex: 1;
  }
`

const StyledCoverImage = styled.div`
  position: relative;
  flex: 0 0 50%;
  aspect-ratio: 1.59/1;
  margin-top: max( var( --grid-gutter ), calc( 4 * var( --stack-basis ) ) );

  button {
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border: 0;
    padding: 0;
    margin: 0;
    background: ${ p => p.theme.colors.black };
    cursor: pointer;

    img {
      opacity: 0.6;
    }

    &:hover svg {
      transform: 
      translate( -50%, -50% )
      scale( 1.1 );
    }
  }

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate( -50%, -50% );
    transition: transform .3s ease-out;
  }
`

export default ThreeDeeTourHighlight
