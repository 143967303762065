import VideoCta from '../VideoCta'

function VideoCtaSegment( {
  anchorId,
  title,
  description,
  coverImage,
  buttonLabel,
  videoUrl,
} ) {
  return (
    <VideoCta
      anchorId={ anchorId }
      title={ title }
      description={ description }
      coverImage={ coverImage }
      buttonLabel={ buttonLabel }
      videoUrl={ videoUrl }
    />
  )
}

export default VideoCtaSegment
