function TickIcon() {
  return (
    <svg 
      width="18"
      height="13"
      viewBox="0 0 18 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path 
        d="M6.86015 11.9028L7.21799 12.252C7.12228 12.35 6.99052 12.4045 6.8535 12.4027C6.71648 12.4009 6.58621 12.3429 6.49315 12.2423L6.86015 11.9028ZM1.44441 5.3133L7.22715 11.5632L6.49315 12.2423L0.710402 5.99245L1.44441 5.3133ZM6.50231 11.5535L17.1422 0.650787L17.8578 1.34921L7.21799 12.252L6.50231 11.5535Z"
        fill="#A2A9AD"
      />
    </svg>
  )
}

export default TickIcon
