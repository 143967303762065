import { useRouter } from 'next/router'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'
import Image from '../Image'
import DaysLeftTag from '../propertyListings/DaysLeftTag'
import { 
  StyledPropertyPrice, 
  StyledPropertyDataList,
  StyledPropertyDataItem,
} from '../propertyListings/PropertyCard'
import PropertyPriceByStatus from '../propertyListings/PropertyPriceByStatus'

const breakpoint = 600

export default function PropertyListingLargeCard( { property, showCountdown = true } ) {
  const router = useRouter()
  const locale = router.locale || router.defaultLocale || 'de'

  const image = {
    url: property.coverImage,
    alt: property.title,
    dateModified: null,
  }

  return (
    <StyledPropertyListingLargeCard 
      href={ property.url || `https://page.brixel.io/${ locale }/${ property.stub }` }
      target="_blank"
      rel="noopener"
    >
      <StyledPropertyImage>
        <Image
          src={ image.url }
          alt={ image.alt }
          layout="fill"
          objectFit="cover"
          sizes={ `
            ( max-width: ${ breakpoint } ) 100vw,
            ( max-width: 1500px ) 50vw,
            750px
          ` }
          dateModified={ image.dateModified }
        />
        { showCountdown && (
          <DaysLeftTag countdownInDays={ property.companyExclusivity?.countdownInDays || 0 } />
        ) }
      </StyledPropertyImage>
      <StyledPropertyDetails>
        <p>{ property.location }</p>
        <h3>{ property.title }</h3>
        <StyledPropertyPrice $status={ property.status }>
          <h4>
            <FormattedMessage
              description="Price label"
              defaultMessage="Price"
              id="bURm/c"
            />
          </h4>
          <p>
            <PropertyPriceByStatus property={ property } />
          </p>
        </StyledPropertyPrice>
        <StyledPropertyDataList>
          <StyledPropertyDataItem>
            <h4>
              <FormattedMessage
                description="Number of rooms label"
                defaultMessage="Rooms"
                id="V2MxOz"
              />
            </h4>
            <p>
              { property.numberOfRooms }
            </p>
          </StyledPropertyDataItem>
          <StyledPropertyDataItem>
            <h4>
              <FormattedMessage
                description="Living area label"
                defaultMessage="Living area"
                id="wDKxsa"
              />
            </h4>
            <p>
              { property.livingArea.value }&thinsp;{ property.livingArea.unit || 'm2' }
            </p>
          </StyledPropertyDataItem>
          <StyledPropertyDataItem>
            <h4>
              <FormattedMessage
                description="Year built label"
                defaultMessage="Year built"
                id="tsDsIk"
              />
            </h4>
            <p>
              { property.constructionYear }
            </p>
          </StyledPropertyDataItem>
        </StyledPropertyDataList>
      </StyledPropertyDetails>
    </StyledPropertyListingLargeCard>
  )
}

const StyledPropertyListingLargeCard = styled.a`
  overflow: hidden;
  position: relative;
  display: flex;
  border-radius: var( --card-border-radius );
  background: white;
  color: inherit;
  text-decoration: none;

  > * {
    flex: 0 0 50%;
  }

  @media ( max-width: ${ breakpoint }px ) {
    flex-direction: column;

    > {
      flex: 0 0 100%;

      &:first-child {
        min-height: 50vw;
      }
    }
  }
`

const StyledPropertyImage = styled.div`
  position: relative;
`

const StyledPropertyDetails = styled.div`
  box-sizing: border-box;
  padding: calc( 3 * var( --stack-basis ) );

  > p {
    margin: 0;
    font-size: ${ p => p.theme.typo.sizes.dropped };
    color: ${ p => p.theme.colors.grey700 };
  }

  > h3 {
    margin-top: 0;
    margin-bottom: calc( 7 * var( --stack-basis ) );
    ${ p => p.theme.typo.semiBold };
    font-size: ${ p => p.theme.typo.sizes.veryLarge };
  }

  ${ StyledPropertyDataItem } {
    p {
      font-size: ${ p => p.theme.typo.sizes.medium };
    }
    
    &:last-child {
      @media ( min-width: ${ breakpoint + 1 }px ) {
        min-width: 120px;
      }
    }
  }
`
