import { getColor } from '../../styles/colors'

export default function ClockIcon( { color = 'green400' } ) {
  return (
    <svg 
      width="18"
      height="17"
      viewBox="0 0 18 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path 
        d="M9.16191 0.166504C4.56191 0.166504 0.836914 3.89984 0.836914 8.49984C0.836914 13.0998 4.56191 16.8332 9.16191 16.8332C13.7702 16.8332 17.5036 13.0998 17.5036 8.49984C17.5036 3.89984 13.7702 0.166504 9.16191 0.166504ZM9.17025 15.1665C5.48691 15.1665 2.50358 12.1832 2.50358 8.49984C2.50358 4.8165 5.48691 1.83317 9.17025 1.83317C12.8536 1.83317 15.8369 4.8165 15.8369 8.49984C15.8369 12.1832 12.8536 15.1665 9.17025 15.1665ZM9.58691 4.33317H8.33691V9.33317L12.7119 11.9582L13.3369 10.9332L9.58691 8.70817V4.33317Z"
        fill={ getColor( color ) }
      />
    </svg>
  )
}
