import { useId } from 'react'
import { FilterGroupControls } from './FilterGroupControls'
import { FilterGroupSummary } from './FilterGroupSummary'
import { FilterGroupProps } from './types'

export type { FilterGroupProps } from './types'

export function FilterGroup( { filters, selected, onChange }: FilterGroupProps ) {
  const key = useId()

  return [
    <FilterGroupControls
      key={ `${ key }-controls` }
      filters={ filters }
      selected={ selected }
      onChange={ onChange }
    />,
    <FilterGroupSummary
      key={ `${ key }-summary` }
      filters={ filters }
      selected={ selected }
      onChange={ onChange }
    />,
  ]
}
