import styled, { css } from 'styled-components'
import { textLink } from '../../styles/links'

function PageSegmentBodyText( { 
  html,
  bodyStyle = 'normal',
}: {
  html: string,
  bodyStyle: 'normal' | 'small',
} ) {
  if ( !html ) {
    return null
  }

  return (
    <StyledPageSegmentBodyText
      $style={ bodyStyle }
      dangerouslySetInnerHTML={ { __html: html } }
    />
  )
}

const StyledPageSegmentBodyText = styled.div<{
  $style: 'normal' | 'small',
}>`
  max-width: 32rem;
  margin: 0 auto;
  text-align: center;
  color: inherit;

  p {
    margin: var( --stack-basis ) 0 0;
    color: inherit;
  }

  a {
    ${ p => textLink( p.theme ) }
  }

  ${ p => p.$style === 'small' && css`
    font-size: ${ p.theme.typo.sizes.dropped };
  ` }
`

export default PageSegmentBodyText
