function VideoPlayIcon() {
  return (
    <svg
      width="62"
      height="62"
      viewBox="0 0 62 62"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle 
        cx="31"
        cy="31"
        r="31"
        fill="white"
      />
      <path 
        d="M39.1142 30.2408L27.6508 20.415C27.0021 19.859 26 20.3199 26 21.1743L26 40.8259C26 41.6802 27.0021 42.1411 27.6508 41.5851L39.1142 31.7593C39.5798 31.3602 39.5798 30.6399 39.1142 30.2408Z"
        fill="#009877"
      />
    </svg>
  )
}

export default VideoPlayIcon
