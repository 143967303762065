import { FormattedMessage } from 'react-intl'
import propertyPriceFormatter from './propertyPriceFormatter'

export default function PropertyPriceByStatus( { property } ) {
  if ( ['ON_SALE', 'TO_RENT'].includes( property.status ) ) {
    return (
      <>
        { !property.price && '—' }
        { property.price && (
          propertyPriceFormatter( property.price.currency ).format( property.price.amount ) 
        ) }
      </>
    )
  }

  if ( property.status === 'ON_REQUEST' ) {
    return (
      <FormattedMessage
        description="Property status: On request"
        defaultMessage="On request"
        id="IX9egs"
      />
    )
  }
  
  if ( property.status === 'SOLD' ) {
    return (
      <FormattedMessage
        description="Property status: Sold"
        defaultMessage="Sold"
        id="GJDsYb"
      />
    )
  }
  
  if ( property.status === 'RESERVED' ) {
    return (
      <FormattedMessage
        description="Property status: Reserved"
        defaultMessage="Reserved"
        id="rU6OoX"
      />
    )
  }

  return null
}
