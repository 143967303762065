import useSWR from 'swr'
import useSWRInfinite from 'swr/infinite'
import type { SWRInfiniteConfiguration } from 'swr/infinite'

function fetcher( key ) {
  return fetch( key ).then( r => r.json() )
}

export default function useHubAPIRequest( key ) {
  return useSWR( key, fetcher )
}

export function useHubAPIRequestInfinite( key, options: SWRInfiniteConfiguration = {} ) {
  return useSWRInfinite( key, fetcher, options )
}
