import { TouchEvent, useRef } from 'react'

type FindDirectionProps = {
  dx: number,
  dy: number,
  offset: number,
}

type FindDirectionReturn = 'up' | 'down' | 'left' | 'right' | null

function findDirection( {
  dx, dy, offset, 
}: FindDirectionProps ): FindDirectionReturn {
  if ( Math.abs( dx ) > Math.abs( dy ) ) {
    if ( dx > offset ) {
      return 'right'
    }
    
    if ( dx < -offset ) {
      return 'left'
    }
  }
  else if ( dy > offset ) {
    return 'down'
  }
  else if ( dy < -offset ) {
    return 'up'
  }

  return null
}

type UseSwipeProps = {
  offset?: number,
  up?: ( dy: number ) => void,
  down?: ( dy: number ) => void,
  left?: ( dx: number ) => void,
  right?: ( dx: number ) => void,
  move?: ( dx: number, dy: number ) => void,
}

type UseSwipeReturn = {
  onTouchStart: ( event: TouchEvent ) => void,
  onTouchMove: ( event: TouchEvent ) => void,
  onTouchEnd: ( event: TouchEvent ) => void,
}

export default function useSwipe( {
  offset = 50,
  up = null,
  down = null,
  left = null,
  right = null,
  move = null,
}: UseSwipeProps ): UseSwipeReturn {
  const hasActed = useRef( false )
  const touchStartX = useRef( null )
  const touchStartY = useRef( null )
  const dx = useRef( null )
  const dy = useRef( null )

  const onTouchStart = ( event: TouchEvent ) => {
    touchStartX.current = event.touches[ 0 ].clientX
    touchStartY.current = event.touches[ 0 ].clientY
  }

  const onTouchMove = ( event: TouchEvent ) => {
    dx.current = event.touches[ 0 ].clientX - touchStartX.current
    dy.current = event.touches[ 0 ].clientY - touchStartY.current
    handler( findDirection( { dx: dx.current, dy: dy.current, offset } ) )
    move?.( dx.current, dy.current )
  }

  const handler = ( direction: FindDirectionReturn ) => {
    if ( hasActed.current ) {
      return
    }

    if ( direction === 'up' ) {
      hasActed.current = true
      up?.( dy.current )
    }
    else if ( direction === 'down' ) {
      hasActed.current = true
      down?.( dy.current )
    }
    else if ( direction === 'left' ) {
      hasActed.current = true
      left?.( dx.current )
    }
    else if ( direction === 'right' ) {
      hasActed.current = true
      right?.( dx.current )
    }
  }

  const onTouchEnd = () => {
    reset()
  }

  const reset = () => {
    touchStartX.current = null
    touchStartY.current = null
    dx.current = null
    dy.current = null
    hasActed.current = false
  }

  return {
    onTouchStart,
    onTouchMove,
    onTouchEnd,
  }
}
