import styled from 'styled-components'
import Image from '../Image'
import Background from '../layout/Background'

function PageSegmentBackground( {
  segmentType,
  anchorId = null,
  hasSectionTitle,
  color = 'transparent',
  image = null,
  additionalBottomPadding = 0,
  hideOverflow = false,
  children,
} ) {
  const classNames = ['bx-page-segment']

  if ( segmentType ) {
    classNames.push( `bx-page-segment-${ segmentType }` )
  }

  classNames.push( `bx-page-segment-${ hasSectionTitle ? 'with-title' : 'without-title' }` )

  return (
    <Background 
      $color={ color }
      style={ { 
        overflow: hideOverflow ? 'hidden' : 'visible',
        paddingBottom: `calc( ${ additionalBottomPadding } * var( --stack-basis ) )`, 
      } }
      className={ classNames.join( ' ' ) }
    >
      <div 
        id={ anchorId || null }
        style={ image && { 
          position: 'relative',
          paddingBottom: '20vh',
        } }
      >
        { image && (
          <StyledBgImage>
            <Image
              src={ image.url }
              dateModified={ image.dateModified }
              alt=""
              layout="fill"
              objectFit="cover"
            />
          </StyledBgImage>
        )}
        { children }
      </div>
    </Background>
  )
}

const StyledBgImage = styled.div`
  position: absolute;
  bottom: 0;
  top: 50%;
  left: 0;
  right: 0;
  overflow: hidden;
`

export default PageSegmentBackground
