import Stack from '../layout/Stack'
import PageSegmentHeading from './PageSegmentHeading'
import Cards from '../cards/Cards'
import Container from '../layout/Container'
import PageSegmentBackground from './PageSegmentBackground'

function CardsSegment( {
  anchorId,
  title,
  subtitle,
  alignment,
  mainImage,
  cards,
  additionalBottomPadding,
} ) {
  return (
    <PageSegmentBackground 
      anchorId={ anchorId }
      segmentType="cards"
      hasSectionTitle={ title || subtitle }
      additionalBottomPadding={ additionalBottomPadding }
      color="ivory"
      image={ mainImage?.[ 0 ] }
    >
      <Container>
        <Stack
          $top={ 12 }
          $bottom={ 12 }
          $between={ 10 }
        >
          <PageSegmentHeading
            title={ title }
            subtitle={ subtitle }
          />
          <Cards
            alignment={ alignment }
            cards={ cards }
          />
        </Stack>
      </Container>
    </PageSegmentBackground>
  )
}

export default CardsSegment
