function PlusIcon() {
  return (
    <svg 
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path 
        d="M1.5 12.625C0.947715 12.625 0.5 13.0727 0.5 13.625C0.5 14.1773 0.947715 14.625 1.5 14.625L1.5 12.625ZM26.5 14.625C27.0523 14.625 27.5 14.1773 27.5 13.625C27.5 13.0727 27.0523 12.625 26.5 12.625L26.5 14.625ZM13 26.125C13 26.6773 13.4477 27.125 14 27.125C14.5523 27.125 15 26.6773 15 26.125L13 26.125ZM15 1.125C15 0.572715 14.5523 0.125 14 0.125C13.4477 0.125 13 0.572715 13 1.125L15 1.125ZM1.5 14.625L26.5 14.625L26.5 12.625L1.5 12.625L1.5 14.625ZM15 26.125L15 1.125L13 1.125L13 26.125L15 26.125Z"
        fill="#009877"
      />
    </svg>
  )
}

export default PlusIcon
