function LongArrowIcon() {
  return (
    <svg 
      width="31"
      height="9"
      viewBox="0 0 31 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path 
        d="M30.3536 4.7764C30.5488 4.58114 30.5488 4.26456 30.3536 4.0693L27.1716 0.887318C26.9763 0.692055 26.6597 0.692055 26.4645 0.887318C26.2692 1.08258 26.2692 1.39916 26.4645 1.59442L29.2929 4.42285L26.4645 7.25128C26.2692 7.44654 26.2692 7.76312 26.4645 7.95839C26.6597 8.15365 26.9763 8.15365 27.1716 7.95839L30.3536 4.7764ZM0 4.92285H30V3.92285H0V4.92285Z"
        fill="#A2A9AD"
      />
    </svg>
  )
}

export default LongArrowIcon
