import { useIntl } from 'react-intl'
import { FilterGroup } from '../FilterGroup/FilterGroup'
import swissCantons from './swissCantons'

export default function PropertyListingsFilterGroup( { selected, onChange } ) {
  const intl = useIntl()

  return FilterGroup( {
    filters: [
      {
        key: 'stateCodes',
        type: 'select',
        label: intl.formatMessage( {
          description: 'Label for the Swiss canton multi select',
          defaultMessage: 'Cantons',
          id: 'REcF0D',
        } ),
        options: Object.entries( swissCantons( intl ) ).map(
          ( [stateCode, cantonName] ) => ( {
            value: stateCode,
            label: cantonName,
          } ),
        ),
      },
    ],
    selected,
    onChange,
  } )
}
