import { useState } from 'react'
import styled from 'styled-components'
import Logo from './Logo'

function Logos( { logos } ) {
  const [positions, setPositions] = useState( logos.map( l => ( { id: l.id, offsetTop: null } ) ) )

  const setPosition = logoId => offsetTop => {
    setPositions( s => {
      const i = s.findIndex( p => p.id === logoId )
      const newPositions = [...s]
      newPositions[ i ].offsetTop = offsetTop
      return newPositions
    } )
  }

  const hasBorder = logoIndex => {
    if ( positions[ logoIndex + 1 ]?.offsetTop === positions[ logoIndex ].offsetTop ) {
      return true
    }

    return false
  }

  return (
    <StyledLogos>
      { logos.map( ( logo, i ) => (
        <Logo
          key={ logo.id }
          label={ logo.label }
          image={ logo.logo[ 0 ] }
          href={ logo.logoLink }
          hasBorder={ hasBorder( i ) }
          setPosition={ setPosition( logo.id ) }
        />
      ) ) }
    </StyledLogos>
  )
}

const StyledLogos = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`

export default Logos
