import { SelectedValues, SelectProps } from './types'

export function defaultSingleSelectDisplayValue(
  selected: string,
  options: SelectProps<string>['options'],
  placeholder?: string,
) {
  return (
    options.find( option => option.value === selected )?.label
    || placeholder
    || ''
  )
}

export function defaultMultipleSelectDisplayValue(
  selected: string[],
  options: SelectProps<string[]>['options'],
  placeholder?: string,
) {
  return (
    selected
      .map(
        selectedValue => options.find( option => option.value === selectedValue )?.label,
      )
      .filter( Boolean )
      .join( ', ' )
    || placeholder
    || ''
  )
}

export function getDisplayValue<TSelectedValues extends SelectedValues>(
  selected: TSelectedValues,
  options: SelectProps<TSelectedValues>['options'],
  placeholder?: string,
  displayValue?: string,
) {
  if ( displayValue ) {
    return displayValue
  }

  if ( Array.isArray( selected ) ) {
    return defaultMultipleSelectDisplayValue( selected, options, placeholder )
  }

  return defaultSingleSelectDisplayValue( selected, options, placeholder )
}
