import React from 'react'
import styled from 'styled-components'
import PinIcon from '../icons/PinIcon'

function Location( { location } ) {
  return (
    <StyledLocation>
      <PinIcon />
      {location.address}
    </StyledLocation>
  )
}

const StyledLocation = styled.p`
  display: flex;
  align-items: center;
  gap: 12px;

  svg {
    color: ${ p => p.theme.colors.green400 };
  }
`

export default Location
