function ChevronIcon() {
  return (
    <svg 
      width="16"
      height="9"
      viewBox="0 0 16 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path 
        d="M7.99967 8L8.36825 8.33786C8.27355 8.44118 8.13983 8.5 7.99967 8.5C7.85952 8.5 7.7258 8.44118 7.6311 8.33786L7.99967 8ZM1.70158 0.38941L8.36825 7.66214L7.6311 8.33786L0.96443 1.06514L1.70158 0.38941ZM7.6311 7.66214L14.2978 0.38941L15.0349 1.06513L8.36825 8.33786L7.6311 7.66214Z"
        fill="#009877"
      />
    </svg>
  )
}

export default ChevronIcon
