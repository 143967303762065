/* eslint-disable jsx-a11y/control-has-associated-label */
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'

const cols = [
  'floor',
  'apartment',
  'numberOfRooms',
  'livingArea',
  'balcony',
  'rent',
  'extraCosts',
  'availability',
] as const

function FloorplanTable( { rows } ) {
  return (
    <StyledOverflow>
      <StyledTable>
        <thead>
          <tr>
            {cols.map( col => {
              if ( col === 'floor' ) {
                return (
                  <th key={ col }>
                    <FormattedMessage 
                      defaultMessage="Floor" 
                      description="Floorplan/units table header: Floor"
                      id="biUozd"
                    />
                  </th>
                )
              }
              if ( col === 'apartment' ) {
                return (
                  <th key={ col }>
                    <FormattedMessage 
                      defaultMessage="Apartment" 
                      description="Floorplan/units table header: Apartment"
                      id="E2Ajt6"
                    />
                  </th>
                )
              }
              if ( col === 'numberOfRooms' ) {
                return (
                  <th key={ col }>
                    <FormattedMessage 
                      defaultMessage="Number of rooms" 
                      description="Floorplan/units table header: Number of rooms"
                      id="JkP/dR"
                    />
                  </th>
                )
              }
              if ( col === 'livingArea' ) {
                return (
                  <th key={ col }>
                    <FormattedMessage 
                      defaultMessage="Living Area" 
                      description="Floorplan/units table header: Living Area"
                      id="t3uTml"
                    />
                  </th>
                )
              }
              if ( col === 'balcony' ) {
                return (
                  <th key={ col }>
                    <FormattedMessage 
                      defaultMessage="Balcony" 
                      description="Floorplan/units table header: Balcony"
                      id="D41ybz"
                    />
                  </th>
                )
              }
              if ( col === 'rent' ) {
                return (
                  <th key={ col }>
                    <FormattedMessage 
                      defaultMessage="Rent" 
                      description="Floorplan/units table header: Rent"
                      id="+2W/WP"
                    />
                  </th>
                )
              }
              if ( col === 'extraCosts' ) {
                return (
                  <th key={ col }>
                    <FormattedMessage 
                      defaultMessage="Extra Costs" 
                      description="Floorplan/units table header: Extra Costs"
                      id="J4JtcB"
                    />
                  </th>
                )
              }
              if ( col === 'availability' ) {
                return (
                  <th key={ col }>
                    <FormattedMessage 
                      defaultMessage="Availability" 
                      description="Floorplan/units table header: Availability"
                      id="mJNLNN"
                    />
                  </th>
                )
              }
              return null
            } )}
          </tr>
        </thead>
        <tbody>
          {rows.map( row => (
            <tr key={ row.id }>
              {cols.map( ( col, i ) => {
                const value = row[ col ]
                if ( i === 0 ) {
                  return <th key={ col }>{value}</th>
                }

                if ( col === 'availability' ) {
                  return (
                    <StyledAvailability $value={ value } key={ col }>
                      <AvailabilityValue value={ value } />
                    </StyledAvailability>
                  )
                }

                if ( col === 'livingArea' ) {
                  return <td key={ col }>{value.replace( 'm2', 'm²' )}</td>
                }

                return (
                  <td key={ col }>{value}</td>
                )
              } )}
            </tr>
          ) )}
        </tbody>
      </StyledTable>
    </StyledOverflow>
  )
}

function AvailabilityValue( { value } ) {
  switch ( value ) {
    case 'available':
      return (
        <span>
          <FormattedMessage defaultMessage="Available" id="CkmV9k" description="Rental property availability: available" />
        </span>
      )
    case 'reserved':
      return (
        <span>
          <FormattedMessage defaultMessage="Reserved" id="oCoQLk" description="Rental property availability: reserved" />
        </span>
      )
    case 'occupied':
      return (
        <span>
          <FormattedMessage defaultMessage="Occupied" id="zxC1er" description="Rental property availability: occupied" />
        </span>
      )
    default:
      return <span>—</span>
  }
}

const StyledAvailability = styled.td<{ $value: string }>`
  color: ${ p => {
    switch ( p.$value ) {
      case 'available':
        return p.theme.colors.green400
      case 'reserved':
        return p.theme.colors.yellow400
      case 'occupied':
        return p.theme.colors.red400
      default:
        return 'inherit' 
    }
  } };
`

const StyledOverflow = styled.div`
  position: relative;
  z-index: 0;
  overflow: scroll;
  margin-right: calc( var( --container-margin-width ) * -1 );
  padding-right: var( --container-margin-width );
`

const StyledTable = styled.table`
  width: 100%;
  border: none;
  border-collapse: separate;
  border-spacing: 0;
  white-space: nowrap;
  text-align: left;


  thead {
    th {
      font-weight: bold;
      padding-bottom: 24px;
      background: white;
      &:first-child {
        position: sticky;
        left: 0;
      }
    }
  }

  th,
  td {
    &:not(:last-child) {
      padding-right: 24px;
    }
  }

  tbody {
    th,
    td {
      border-bottom: 1px solid ${ p => p.theme.colors.grey200 };
      padding: 14px 0;
    }

    th {
      position: sticky;
      left: 0;
      background: white;
      z-index: 1;
    }
  }
`

export default FloorplanTable
