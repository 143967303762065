import Stack from '../layout/Stack'
import Container from '../layout/Container'
import PageSegmentBackground from './PageSegmentBackground'
import PageSegmentHeading from './PageSegmentHeading'
import ButtonGroup from '../ButtonGroup'
import Background from '../layout/Background'
import HouseInHand from '../icons/brixel/HouseInHand'
import MagnifyingGlass from '../icons/brixel/MagnifyingGlass'
import GoGridIcon from '../icons/grid/GoGridIcon'
import ArrowIcon from '../icons/ArrowIcon'

function TextCtaSegment( {
  anchorId,
  title,
  subtitle,
  theme,
  icon,
  buttons,
  additionalBottomPadding,
} ) {
  const getThemeColors = () => {
    if ( theme === 'green' ) {
      return {
        bgColor: 'green400',
        textColor: 'realWhite',
        buttonColor: 'darkGreen',
        iconColor: 'green700',
      }
    }
    
    if ( theme === 'darkGreen' ) {
      return {
        bgColor: 'green700',
        textColor: 'realWhite',
        buttonColor: 'green',
        iconColor: 'green400',
      }
    }
    
    if ( theme === 'blue' ) {
      return {
        bgColor: 'blue400',
        textColor: 'realWhite',
        buttonColor: 'blue',
        iconColor: 'blue700',
      }
    }

    return {
      bgColor: 'transparent',
      textColor: 'black',
      buttonColor: 'green',
      iconColor: 'grey700',
    }
  }

  const {
    bgColor, textColor, buttonColor, iconColor, 
  } = getThemeColors()

  return (
    <PageSegmentBackground 
      anchorId={ anchorId }
      color={ bgColor }
      segmentType="textCta"
      hasSectionTitle={ title || subtitle }
      additionalBottomPadding={ additionalBottomPadding }
    >
      <Container>
        <Stack
          $top={ 12 }
          $bottom={ 12 }
          $between={ 4 }
        >
          { icon && (
            <div style={ {
              display: 'flex',
              justifyContent: 'center',
            } }
            >
              { icon === 'goGrid' && (
                <GoGridIcon />
              ) }
              { icon === 'houseInHand' && (
                <HouseInHand color={ iconColor } />
              ) }
              { icon === 'magnifyingGlass' && (
                <MagnifyingGlass color={ iconColor } />
              ) }
            </div>
          )}
          <Background $textColor={ textColor }>
            <PageSegmentHeading
              title={ title }
              subtitle={ subtitle }
              size="hero"
            />
          </Background>
          <ButtonGroup 
            buttons={ buttons.map( b => ( { 
              ...b, 
              color: buttonColor, 
              postIcon: b.primary && <ArrowIcon />, 
            } ) ) } 
          />
        </Stack>
      </Container>
    </PageSegmentBackground>
  )
}

export default TextCtaSegment
