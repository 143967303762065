import styled from 'styled-components'
import TickIcon from '../icons/TickIcon'

function Bullets( { bullets } ) {
  return (
    <StyledBullets>
      { bullets.map( ( bullet, i ) => (
        <StyledBullet 
          // eslint-disable-next-line react/no-array-index-key
          key={ i }
          $highlight={ bullet.highlight }
        >
          <TickIcon />
          { bullet.text }
        </StyledBullet>
      ) ) }
    </StyledBullets>
  )
}

const StyledBullets = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`

const StyledBullet = styled.li<{
  $highlight: boolean,
}>`
  display: flex;
  align-items: flex-start;
  margin-bottom: calc( var( --stack-basis ) * 2 );
  font-size: ${ p => p.theme.typo.sizes.dropped };
  font-weight: ${ p => p.$highlight ? 600 : 400 };

  svg {
    flex: 0 0 18px;
    margin: 5px 0.8rem 0 0;
  }
`

export default Bullets
