import styled from 'styled-components'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { wrapWithPTags } from '@/lib/strings'
import PageSegmentHeading from './PageSegmentHeading'
import PageSegmentBackground from './PageSegmentBackground'
import Container from '../layout/Container'
import Stack from '../layout/Stack'
import Map from '../Map'
import Image from '../Image'
import DefinitionList from '../DefinitionList'
import Location from '../rentals/Location'

const BREAKPOINT = 900

function LocalAreaSegment( {
  title,
  anchorId,
  distances,
  location,
  image,
  subtitle,
  bodyText,
} ) {
  return (
    <PageSegmentBackground
      anchorId={ anchorId }
      color="ivory"
      segmentType="localArea"
      hasSectionTitle={ title }
    >
      <Container>
        <Stack
          $top={ 12 }
          $bottom={ 12 }
          $between={ 10 }
        >
          <PageSegmentHeading align="left" title={ title } />

          <StyledSection>
            <div>
              <Map { ...{ location } } />
            </div>
            <div>
              {location && (
                <Location { ...{ location } } />
              )}

              <h3>
                <FormattedMessage
                  defaultMessage="Distances"
                  id="xHyaHg"
                  description="Distances"
                />
              </h3>
              <DefinitionList
                items={ distances.map( ( { place, id, distance } ) => ( {
                  key: id,
                  term: place,
                  data: distance, 
                } ) ) }
              />
            </div>
          </StyledSection>

          <StyledSection>
            <div>
              {subtitle && <h2>{subtitle}</h2>}
              {bodyText && (
                // eslint-disable-next-line react/no-danger
                <div dangerouslySetInnerHTML={ { __html: wrapWithPTags( bodyText ) } } />
              )}
            </div>
            <div>
              <StyledImage>
                {image && (
                <Image
                  src={ image.url }
                  alt={ image.alt }
                  width={ image.width }
                  height={ image.height }
                  dateModified={ image.dateModified }
                />
                )}
              </StyledImage>
            </div>
          </StyledSection>
        </Stack>
      </Container>
    </PageSegmentBackground>
  )
}

const StyledSection = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${ BREAKPOINT }px) {
    flex-direction: row;
    --gutter: 122px;
    column-gap: var(--gutter);
    > div {
      &:first-child {
        flex: 1 0 calc(60% - var(--gutter));
      }
      &:last-child {
        flex: 1 0 calc(40% - var(--gutter));
      }
    }
  }
`

const StyledImage = styled.div`
  @media (min-width: ${ BREAKPOINT }px) {
    margin-right: calc(var(--container-margin-width) * -1);
  }
  // margin-left: calc(var(--container-margin-width) * -1);
`

export default LocalAreaSegment
