export function getEmbedUrl( videoUrl: string ) {
  const youTubeRegExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/
  const youTubeMatch = videoUrl.match( youTubeRegExp )

  if ( youTubeMatch && youTubeMatch[ 2 ]?.length === 11 ) {
    return `//www.youtube-nocookie.com/embed/${ youTubeMatch[ 2 ] }`
  }

  const vimeoRegExp = /(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/(?:[^/]*)\/videos\/|album\/(?:\d+)\/video\/|video\/|)(\d+)(?:[a-zA-Z0-9_-]+)?/i
  const vimeoMatch = videoUrl.match( vimeoRegExp )

  if ( vimeoMatch && vimeoMatch[ 1 ]?.length > 1 ) {
    return `//player.vimeo.com/video/${ vimeoMatch[ 1 ] }?dnt=1`
  }
  
  return null
}

export function getYoutubeId( videoUrl: string ) {
  const youTubeRegExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/
  const youTubeMatch = videoUrl.match( youTubeRegExp )

  if ( youTubeMatch && youTubeMatch[ 2 ]?.length === 11 ) {
    return youTubeMatch[ 2 ]
  }
  return false
}
