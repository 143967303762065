import styled, { css } from 'styled-components'
import Button, { StyledButton } from '../Button'
import Image from '../Image'

function Card( { alignment, card } ) {
  const img = card.cardImage?.[ 0 ]
  
  const imgAlignment = () => {
    if ( ['center', 'bottomLeft'].includes( card.cardImageAlignment ) ) {
      return card.cardImageAlignment
    }

    return null
  }

  return (
    <StyledCard $alignment={ alignment }>
      { img && (
        <StyledCardImage 
          $alignment={ imgAlignment() }
          $color={ card.cardColor }
          $imgWidth={ img.width }
        >
          <div>
            <Image
              src={ img.url }
              alt={ img.alt }
              width={ img.width }
              height={ img.height }
              dateModified={ img.dateModified }
            />
          </div>
        </StyledCardImage>
      ) }
      <StyledCardContent>
        <h4>{ card.cardTitle }</h4>
        <div 
        // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={ { __html: card.cardDescription } }
        />
        { card.cardButtonLink && card.cardButtonLabel && (
          <Button
            href={ card.cardButtonLink }
            fullWidth
          >
            { card.cardButtonLabel }
          </Button>
        ) }
      </StyledCardContent>
    </StyledCard>
  )
}

export const StyledCardImage = styled.div<{ 
  $alignment: 'center' | 'bottomLeft',
  $color: 'grey' | 'blue' | 'green' | 'red',
  $imgWidth: number, 
}>`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  min-height: 154px;

  ${ p => p.$color === 'grey' && css`
    background: ${ p.theme.colors.grey100 };
  ` }
  
  ${ p => p.$color === 'blue' && css`
    background: ${ p.theme.colors.blue100 };
  ` }
  
  ${ p => p.$color === 'green' && css`
    background: ${ p.theme.colors.green100 };
  ` }
  
  ${ p => p.$color === 'red' && css`
    background: ${ p.theme.colors.red100 };
  ` }

  > div {
    position: relative;
    margin: 0 var( --grid-gutter );
    width: ${ p => p.$imgWidth }px;
  }

  ${ p => p.$alignment === 'center' && css`
    justify-content: center;
    align-items: center;
  ` }
`

export const StyledCardContent = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: calc( 4 * var( --stack-basis ) ) var( --grid-gutter );

  > h4 {
    margin: 0 0 var( --stack-basis ) 0;
  }

  > div > * {
    margin: 1rem 0;
    font-size: ${ p => p.theme.typo.sizes.dropped };

    &:first-child {
      margin-top: 0;
    }
  }

  ${ StyledButton } {
    margin-top: auto;
  }
`

const StyledCard = styled.div<{ $alignment: 'left' | 'center' }>`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  width: calc( 100% - var( --grid-gutter ) );
  max-width: 418px;
  min-width: 200px;
  border-radius: var( --card-border-radius );
  background: white;

  ${ p => p.$alignment === 'center' && css`
    ${ StyledCardImage } {
      justify-content: center;
      align-items: center;
    }

    ${ StyledCardContent } {
      text-align: center;
    }
  ` }

  @media ( min-width: 600px ) {
    width: calc( 33.333% - var( --grid-gutter ) );
    min-width: 270px;
  }
`

export default Card
