import Stack from '../layout/Stack'
import Container from '../layout/Container'
import PageSegmentBackground from './PageSegmentBackground'
import PageSegmentHeading from './PageSegmentHeading'
import Postcards from '../postcards/Postcards'

function PostardsSegment( {
  anchorId,
  title,
  subtitle,
  postcards,
  additionalBottomPadding,
} ) {
  return (
    <PageSegmentBackground 
      anchorId={ anchorId }
      segmentType="packageCards"
      hasSectionTitle={ title || subtitle }
      additionalBottomPadding={ additionalBottomPadding }
    >
      <Container>
        <Stack
          $top={ 12 }
          $bottom={ 12 }
          $between={ 10 }
        >
          <PageSegmentHeading
            title={ title }
            subtitle={ subtitle }
          />
          <Postcards
            postcards={ postcards }
          />
        </Stack>
      </Container>
    </PageSegmentBackground>
  )
}

export default PostardsSegment
