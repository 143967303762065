import styled from 'styled-components'
import ProcessStep from './ProcessStep'

const breakpoint = '980px'

function ProcessSteps( { processSteps } ) {
  return (
    <div>
      <StyledProcessSteps>
        { processSteps.map( step => (
          <ProcessStep
            key={ step.id }
            text1={ step.text1 }
            text2={ step.text2 }
            color={ step.color }
            highlight={ step.highlight }
            breakpoint={ breakpoint }
          />
        ) ) }
      </StyledProcessSteps>
    </div>
  )
}

const StyledProcessSteps = styled.ol`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin: 0;
  padding: 0;

  > *:last-child svg {
    display: none;
  }

  @media ( max-width: ${ breakpoint } ) {
    flex-direction: column;
  }
`

export default ProcessSteps
