import useHubAPIRequest from '../../hubApi/useHubAPIRequest'
import Stack from '../layout/Stack'
import PropertyListingLargeCard from './PropertyListingLargeCard'

export default function PropertyListingsLarge( { 
  companyStub, 
  related = false,
} ) {
  const endpoint = `/api/company/stub/${ companyStub }/portfolio/${ related ? 'related' : 'others' }?limit=30`
  const { data: properties, error } = useHubAPIRequest( endpoint )

  if ( !properties && !error ) {
    return <p>Loading</p>
  }

  return (
    <div>
      <Stack
        $between={ 2 }
      >
        { properties.map( property => (
          <div key={ property.id }>
            <PropertyListingLargeCard property={ property } />
          </div>
        ) ) }
      </Stack>
    </div>
  )
}
