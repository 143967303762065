import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'
import Image from '../Image'

function Testimonial( { testimonial } ) {
  const image = testimonial.mainImage[ 0 ]

  return (
    <StyledTestimonial>
      <StyledImage>
        <Image
          src={ image.url }
          alt={ image.alt }
          dateModified={ image.dateModified }
          layout="fill"
          objectFit="cover"
        />
      </StyledImage>
      <blockquote>
        <p>“{ testimonial.description }”</p>
        <footer>
          <cite>{ testimonial.title }</cite>
          <span>
            { testimonial.amountSaved }
            { ' ' }
            <FormattedMessage
              description="saved"
              defaultMessage="saved"
              id="KHged8"
            />
          </span>
        </footer>
      </blockquote>
    </StyledTestimonial>
  )
}

const StyledTestimonial = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  blockquote {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    ${ p => p.theme.typo.semiBold };
    font-size: ${ p => p.theme.typo.sizes.large };

    p {
      margin: 0 0 calc( 3 * var( --stack-basis ) ) 0;
    }

    footer {
      margin-top: auto;
      color: ${ p => p.theme.colors.green400 };
      font-size: ${ p => p.theme.typo.sizes.normal };

      cite {
        display: block;
        font-style: normal;
      }
      
      span {
        display: block;
        font-weight: 400;
      }
    }
  }
`

const StyledImage = styled.div`
  overflow: hidden;
  position: relative;
  width: 100px;
  height: 100px;
  border-radius: 100%;
  margin-bottom: calc( 3 * var( --stack-basis ) );
`

export default Testimonial
