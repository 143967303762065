import Stack from '../layout/Stack'
import PageSegmentBackground from './PageSegmentBackground'
import PageSegmentHeading from './PageSegmentHeading'
import LargePostcards from '../largePostcards/LargePostcards'
import Container from '../layout/Container'

function LargePostardsSegment( {
  anchorId,
  title,
  subtitle,
  postcards,
  additionalBottomPadding,
  headingBackgroundColor,
} ) {
  return (
    <PageSegmentBackground 
      anchorId={ anchorId }
      segmentType="largePostcards"
      hasSectionTitle={ title || subtitle }
      additionalBottomPadding={ additionalBottomPadding }
      color={ getBGColor( headingBackgroundColor, postcards ) }
    >
      <Stack
        $top={ 12 }
        $between={ 10 }
      >
        <Container>
          <PageSegmentHeading
            title={ title }
            subtitle={ subtitle }
          />
        </Container>
        <LargePostcards
          postcards={ postcards }
        />
      </Stack>
    </PageSegmentBackground>
  )
}

function getBGColor( headingBackgroundColor, cards ) {
  if ( headingBackgroundColor === 'matchFirstCard' ) {
    return `${ cards[ 0 ].cardColor }100`
  }

  if ( headingBackgroundColor === 'white' ) {
    return 'white'
  }

  return 'ivory'
}

export default LargePostardsSegment
