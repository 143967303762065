import { useEffect, useState } from 'react'

function getMatches( query: string ): boolean {
  // Prevents SSR issues
  if ( typeof window !== 'undefined' ) {
    return window.matchMedia( query ).matches
  }
  return false
}

export default function useMediaQuery( query: string ): boolean {
  const [matches, setMatches] = useState<boolean>( getMatches( query ) )

  useEffect( () => {
    const matchMedia = window.matchMedia( query )

    const handleChange = () => {
      setMatches( getMatches( query ) )
    }

    handleChange()

    matchMedia.addEventListener( 'change', handleChange )

    return () => {
      matchMedia.removeEventListener( 'change', handleChange )
    }
  }, [query] )

  return matches
}
