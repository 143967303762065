import styled, { css } from 'styled-components'
import Stack, { StackItem } from '../layout/Stack'
import Button from '../Button'
import Bullets from './Bullets'
import Additional from './Additional'
import Link from '../Link'

function Package( { thePackage } ) {
  return (
    <StyledPackage 
      $highlight={ thePackage.highlight }
      $withAdditional={ thePackage.packageAdditional?.length > 0 }
    >
      <div>
        <Stack $between={ 2 }>
          <h3>{ thePackage.packageName }</h3>
          <p>{ thePackage.packageDescription }</p>
          <StyledPrice>
            { thePackage.originalPrice && (
              <span>
                { thePackage.originalPriceLabel && `${ thePackage.originalPriceLabel }: ` }
                <s>{ thePackage.originalPrice }</s>
                { thePackage.discountHighlight && <i>{ thePackage.discountHighlight }</i> }
              </span>
            ) }
            <p>
              { thePackage.price }
              { thePackage.pricePostfix && <span>{ thePackage.pricePostfix }</span> }
            </p>
          </StyledPrice>
          <div>
            <Button
              href={ thePackage.buttonLink }
              outline={ !thePackage.highlight }
              color={ thePackage.highlight ? 'green' : 'black' }
              fullWidth
            >
              { thePackage.buttonLabel }
            </Button>
          </div>
          { thePackage.bulletPoints?.length > 0 && (
            <StackItem $top={ 5 }>
              <Bullets bullets={ thePackage.bulletPoints } />
            </StackItem>
          ) }
          { thePackage.moreInfoLink && thePackage.moreInfoLabel && (
            <StackItem $top={ 4 }>
              <StyledMoreInfoLink>
                <Link href={ thePackage.moreInfoLink }>
                  { thePackage.moreInfoLabel }
                </Link>
              </StyledMoreInfoLink>
            </StackItem>
          ) }
        </Stack>
      </div>
      { thePackage.packageAdditional?.length > 0 && (
        <Additional additional={ thePackage.packageAdditional } />
      ) }
    </StyledPackage>
  )
}

const StyledPrice = styled.div``

const StyledPackage = styled.div<{
  $highlight: boolean,
  $withAdditional: boolean,
}>`
  --highlight-color: ${ p => p.$highlight ? p.theme.colors.green400 : p.theme.colors.black };
  --flex-basis: 300px;
  --max-width: 450px;
  /* --min-width: calc( var( --flex-basis ) * 0.6 ); */
  display: flex;
  box-sizing: border-box;
  flex: 1 1 var( --flex-basis );
  /* min-width: var( --min-width ); */
  max-width: calc( ${ p => p.$withAdditional ? 2 : 1 } * var( --max-width ) );
  border-radius: var( --card-border-radius );
  border-top: 16px solid var( --highlight-color );
  background: ${ p => p.theme.colors.realWhite };
  box-shadow: 0px 6px 12px rgba( 0, 0, 0, 0.1 );
  
  > div {
    box-sizing: border-box;
    padding: 2rem 2rem 3rem;

    &:nth-child( 2 ) {
      position: relative;
      flex: 0 0 50%;
      background: ${ p => p.theme.colors.green100 };
    }
  }

  h3 {
    margin: 0;
    ${ p => p.theme.typo.semiBold }
    font-size: ${ p => p.theme.typo.sizes.normal };
    color: var( --highlight-color );
  }

  p {
    font-size: ${ p => p.theme.typo.sizes.dropped };
  }

  ${ StyledPrice } {
    margin-top: calc( -1 * var( --stack-basis ) );
    min-height: 3.5rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    > span {
      margin-bottom: 0.5rem;
      font-size: ${ p => p.theme.typo.sizes.small };
      color: ${ p => p.theme.colors.grey700 };

      > i {
        display: inline-block;
        padding: 8px;
        margin-left: 1em;
        font-size: ${ p => p.theme.typo.sizes.normal };
        font-weight: 500;
        font-style: normal;
        color: ${ p => p.theme.colors.green400 };
        background: ${ p => p.theme.colors.green100 };
      }
    }

    p {
      margin: 0;
      ${ p => p.theme.typo.semiBold }
      font-size: ${ p => p.theme.typo.sizes.veryLarge };
      line-height: 1.1;

      span {
        margin-left: 1em;
        font-size: ${ p => p.theme.typo.sizes.small };
      }

      @media ( max-width: 500px ) {
        font-size: ${ p => p.theme.typo.sizes.medium };
      }
    }
  }

  ${ p => p.$withAdditional && css`
    flex-grow: 2;

    @media ( min-width: 651px ) {
      flex-basis: calc( var( --flex-basis ) * 2 );
      /* min-width: calc( var( --min-width ) * 2 ); */
    }
    
    @media ( max-width: 650px ) {
      flex-wrap: wrap;
    }
  ` }
`

const StyledMoreInfoLink = styled.div`
  text-align: center;

  a {
    ${ p => p.theme.typo.medium }
    font-size: ${ p => p.theme.typo.sizes.normal };
    color: ${ p => p.theme.colors.green400 };
    text-decoration-thickness: 1px;
    text-underline-offset: 3px;
    text-decoration-color: transparent;
    transition: text-decoration-color .3s ease-out;

    &:hover {
      text-decoration-color: currentColor;
    }
  }
`

export default Package
