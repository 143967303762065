import styled from 'styled-components'
import Container from '../layout/Container'
import PageSegmentBackground from './PageSegmentBackground'
import { getYoutubeId } from '../../lib/video'
import YoutubeModal from '../YoutubeModal'
import { slugify } from '../../lib/strings'

function VideoGroupSegment( {
  anchorId,
  title,
  subtitle,
  videos,
  additionalBottomPadding,
} ) {
  return (
    <PageSegmentBackground 
      anchorId={ anchorId }
      segmentType="processSteps"
      hasSectionTitle={ title || subtitle }
      additionalBottomPadding={ additionalBottomPadding }
    >
      <VideoGroup>
        <Container>
          <header>
            <div>
              <h2>{title}</h2>
            </div>
            {subtitle && (
              <div>
                <p>{subtitle}</p>
              </div>
            )}
          </header>
      
          <VideoGrid>
            {videos.map( video => {
              const id = getYoutubeId( video.videoUrl )
              return (
                <div key={ video.id }>
                  <YoutubeModal
                    id={ `watch-${ slugify( video.videoTitle ) }` }
                    videoUrl={ video.videoUrl }
                    content={ (
                      <div>
                        <h4>{video.videoTitle}</h4>
                        <div
                          dangerouslySetInnerHTML={ { __html: video.videoDescription } }
                        />
                      </div>
                    ) }
                  >
                    <VideoThumbnail>
                      <img src={ `https://img.youtube.com/vi/${ id }/maxresdefault.jpg` } alt={ video.videoTitle } />
                      <svg width="35" height="41" viewBox="0 0 35 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M35 20.5161L0.499998 40.4347L0.5 0.597527L35 20.5161Z" fill="#009877" />
                      </svg>
                    </VideoThumbnail>
                    <h4>{video.videoTitle}</h4>
                  </YoutubeModal>
                </div>
              )
            } )}
          </VideoGrid>
        </Container>
      </VideoGroup>
    </PageSegmentBackground>
  )
}

const VideoGroup = styled.div`
  padding-top: 2rem;

  header {
    display: flex;
    flex-wrap: wrap;
    margin-left: -1.2rem;

    > div {
      box-sizing: border-box;
      padding-left: 1.2rem;
      width: 100%;
      @media ( min-width: 900px ) {
        width: 50%;
      }
    }
  }

  p {
    display: block;
    max-width: 30rem;
    margin-bottom: 4rem;
  }
`

const VideoGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: -1.2rem;
  margin-top: -2rem;

  > div {
   box-sizing: border-box;
   padding-left: 1.2rem;
   padding-top: 2rem;
   width: 100%;
   @media ( min-width: 900px ) {
      width: 50%;
    }
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  h4 {
    font-size: ${ p => p.theme.typo.sizes.normal };
  }
`

const VideoThumbnail = styled.div`
  padding-bottom: ${ ( ( 9 / 16 ) * 100 ) - 1 }%;
  position: relative;
  overflow: hidden;
  img {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    transform: translateY(-50%);
  }

  svg {
    position: absolute;
    inset: 0;
    margin: auto;
  }
`

export default VideoGroupSegment
