import Stack from '../layout/Stack'
import PageSegmentHeading from './PageSegmentHeading'
import Container from '../layout/Container'
import PageSegmentBackground from './PageSegmentBackground'
import LogoGrid from '../LogoGrid'

function LogoGridSegment( {
  anchorId,
  title,
  subtitle,
  linkedLogos,
  additionalBottomPadding,
} ) {
  return (
    <PageSegmentBackground 
      anchorId={ anchorId }
      color="ivory"
      segmentType="logoGrid"
      hasSectionTitle={ title || subtitle }
      additionalBottomPadding={ additionalBottomPadding }
    >
      <Container>
        <Stack
          $top={ 12 }
          $bottom={ 12 }
          $between={ 10 }
        >
          <PageSegmentHeading
            title={ title }
            subtitle={ subtitle }
          />
          <LogoGrid linkedLogos={ linkedLogos } />
        </Stack>
      </Container>
    </PageSegmentBackground>
  )
}

export default LogoGridSegment
