import styled from 'styled-components'
import LargePostcard from './LargePostcard'

function LargePostcards( { postcards } ) {
  return (
    <StyledLargePostcards>
      { postcards.map( ( card, i ) => (
        <LargePostcard
          key={ card.id }
          layout={ i % 2 ? 'image-left' : 'image-right' }
          heading={ card.heading }
          bodyText={ card.bodyText }
          image={ card.image[ 0 ] }
          cardColor={ card.cardColor }
        />
      ) ) }
    </StyledLargePostcards>
  )
}

const StyledLargePostcards = styled.div`
  display: flex;
  flex-direction: column;
`

export default LargePostcards
