import styled, { css } from 'styled-components'
import { textLink } from '../../styles/links'
import Image from '../Image'
import Container from '../layout/Container'

function LargePostcard( {
  heading,
  bodyText,
  image,
  cardColor,
  layout,
} ) {
  return (
    <StyledPostcardBackground 
      $color={ cardColor }
    >
      <Container>
        <StyledPostcard $layout={ layout }>
          <StyledPostcardText $color={ cardColor }>
            <h3>{ heading }</h3>
            <div 
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={ { __html: bodyText } } 
            />
          </StyledPostcardText>
          <StyledPostcardImage>
            <Image
              src={ image.url }
              alt={ image.alt }
              dateModified={ image.dateModified }
              layout="fill"
              objectFit="contain"
            />
          </StyledPostcardImage>
        </StyledPostcard>
      </Container>
    </StyledPostcardBackground>
  )
}

const textLinkColorMap = {
  blue: 'blue400',
  grey: 'grey700',
  green: 'green400',
  red: 'red400',
}

const coloredTextLink = ( theme, cardColor ) => textLink( theme, textLinkColorMap[ cardColor ] )

const headingColorMap = {
  blue: 'blue700',
  grey: 'grey700',
  green: 'green700',
  red: 'red700',
}

const coloredHeading = ( theme, color ) => css`
  color: ${ theme.getColor( headingColorMap[ color ] ) };
`

const StyledPostcardBackground = styled.div<{
  $color: 'blue' | 'grey' | 'green' | 'red',
}>`
  ${ p => p.$color === 'grey' && css`
    background-color: ${ p.theme.colors.grey100 };
  ` }

  ${ p => p.$color === 'blue' && css`
    background-color: ${ p.theme.colors.blue100 };
  ` }

  ${ p => p.$color === 'green' && css`
    background-color: ${ p.theme.colors.green100 };
  ` }
  
  ${ p => p.$color === 'red' && css`
    background-color: ${ p.theme.colors.red100 };
  ` }
`

const StyledPostcard = styled.div<{
  $layout: 'image-left' | 'image-right',
}>`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: stretch;
  min-height: 423px;
  flex-direction: ${ p => p.$layout === 'image-right' ? 'row' : 'row-reverse' };
  gap: calc( 4 * var( --stack-basis ) );
  padding: calc( 8 * var( --stack-basis ) ) 0;

  /* @media ( max-width: 900px ) {
    padding: calc( 4 * var( --stack-basis ) ) 0;
  }
  
  @media ( max-width: 600px ) {
    padding: calc( 3 * var( --stack-basis ) ) 0;
  } */
`

const StyledPostcardText = styled.div<{
  $color: 'blue' | 'grey' | 'green' | 'red',
}>`
  position: relative;
  box-sizing: border-box;
  flex: 1 1 50%;
  min-width: 250px;
  max-width: 450px;

  &:first-child {
    h3 {
      ${ p => p.theme.typo.semiBold }
      font-size: ${ p => p.theme.typo.sizes.large };
      line-height: 1.2;
      margin: 0 0 calc( 4 * var( --stack-basis ) ) 0;
      ${ p => coloredHeading( p.theme, p.$color ) }
    }

    h4 {
      margin: calc( 3 * var( --stack-basis ) ) 0 var( --stack-basis );
      font-size: ${ p => p.theme.typo.sizes.normal };
    }

    p {
      margin: 0 0 calc( 3 * var( --stack-basis ) ) 0;
      font-size: ${ p => p.theme.typo.sizes.dropped };

      &:first-child {
        margin-top: 0;
      }
      
      &:last-child {
        margin-bottom: 0;
      }
    }

    a {
      ${ p => coloredTextLink( p.theme, p.$color ) }
    }
  }
`

const StyledPostcardImage = styled.div`
  position: relative;
  flex: 1 1 50%;
  max-width: 600px;
  min-width: 250px;
  min-height: 250px
`

export default LargePostcard
