import styled from 'styled-components'
import React from 'react'
import * as Dialog from '@radix-ui/react-dialog'
import { useRouter } from 'next/router'
import VideoEmbed from './VideoEmbed'

type YoutubeModalProps = {
  videoUrl: string;
  id: string;
  children: React.ReactNode;
  content: React.ReactNode
}

function YoutubeModal( {
  videoUrl, id, content, children, 
}: YoutubeModalProps ) {
  const router = useRouter()
  const hash = router.asPath.split( '#' )[ 1 ] || ''
  const basePath = router.asPath.split( '#' )[ 0 ]

  const handleClick = ( e: React.MouseEvent<HTMLAnchorElement> ) => {
    e.preventDefault()
    router.replace( `${ basePath }#${ id }` )
  }
  return (
    <Dialog.Root
      open={ hash === id }
      onOpenChange={ open => {
        if ( !open ) {
          router.replace( basePath, basePath, { scroll: false } )
        }
      } }
    >
      <Dialog.Trigger asChild>
        <a
          href={ `#${ id }` }
          onClick={ handleClick }
        >
          {children}
        </a>
      </Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay asChild>
          <Overlay />
        </Dialog.Overlay>
        <Dialog.Content asChild>
          <Content>
            <VideoFrame>
              <VideoEmbed url={ videoUrl } autoplay />
            </VideoFrame>
            {content}
          </Content>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}

const Overlay = styled.div`
  position: fixed;
  inset: 0;
  z-index: 99;
  background-color: rgba(6, 38, 45, 0.8);
`

const Content = styled.div`
  z-index: 100;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90vw;
  max-width: 900px;
  max-height: 90vh;
  overflow: auto;
  padding: 25px;
  color: ${ p => p.theme.colors.white };

  h4 {
    margin: 1rem 0 0;
  }

  p {
    margin: 1rem 0 0;
  }

  a {
    color: inherit;
  }

`

const VideoFrame = styled.div`
  position: relative;
  aspect-ratio: 16 / 9;
  width: 100%;
  background: #000;
`

export default YoutubeModal
