import styled from 'styled-components'
import Expert from './Expert'

function Experts( { experts } ) {
  return (
    <StyledExperts>
      { experts.map( expert => (
        <Expert
          key={ expert.id }
          expert={ expert }
        />
      ) ) }
    </StyledExperts>
  )
}

const StyledExperts = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: calc( 2 * var( --grid-gutter ) );

  > * {
    max-width: 305px;
    flex: 0 0;
  }
`

export default Experts
