import styled from 'styled-components'
import ProcessStepSimple from './ProcessStepSimple'

export default function ProcessStepsSimple( { steps } ) {
  return (
    <StyledProcessStepsSimple>
      { steps.map( ( step, i ) => (
        <ProcessStepSimple
          key={ step.id }
          step={ step }
          stepNumber={ i + 1 }
        />
      ) ) }
    </StyledProcessStepsSimple>
  )
}

const StyledProcessStepsSimple = styled.ul`
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;

  > li {
    display: flex;
    padding: 0;

    span {
      display: block;

      &:first-child {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50px;
        height: 50px;
        border-radius: 100%;
        background-color: ${ ( { theme } ) => theme.colors.red200 };
        color: ${ ( { theme } ) => theme.colors.red400 };
        ${ ( { theme } ) => theme.typo.mono };
      }

      &:last-child {
        font-weight: 600;
      }
    }
  }

  @media ( max-width: 999px ) {
    flex-direction: column;
    gap: 1rem;

    li {
      background-color: ${ ( { theme } ) => theme.colors.red100 };
      border-radius: 20rem;

      span {
        &:last-child {
          display: flex;
          align-items: center;
          padding-left: 1rem;
        }
      }
    }
  }
  
  @media ( min-width: 1000px ) {
    li {
      flex: 0 0 20%;
      flex-direction: column;
      align-items: center;

      span {
        display: block;

        &:first-child {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 50px;
          height: 50px;
          border-radius: 100%;
          background-color: ${ ( { theme } ) => theme.colors.red200 };
          color: ${ ( { theme } ) => theme.colors.red400 };
          ${ ( { theme } ) => theme.typo.mono };
        }

        &:last-child {
          margin-top: 1rem;
        }
      }
    }
  }
`
