import { useRouter } from 'next/router'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'
import Image from '../Image'
import Link from '../Link'
import DaysLeftTag from './DaysLeftTag'
import PropertyPriceByStatus from './PropertyPriceByStatus'

type Property = {
  brixelPageUrl?: string
  url?: string
  stub: string
  coverImage: string
  title: string
  status: 'ON_REQUEST' | 'SOLD' | 'RESERVED' | 'ON_SALE' | 'TO_RENT'
  companyExclusivity?: {
    countdownInDays: number
  }
  numberOfRooms: number
  livingArea?: {
    value: number
    unit: string
  }
  lotArea?: {
    value: number
    unit: string
  }
  constructionYear: number
}

export default function PropertyCard( { property, showCountdown }: {
  property: Property
  showCountdown?: boolean
} ) {
  const router = useRouter()
  const locale = router.locale || router.defaultLocale || 'de'

  const isBuildingLand = (
    !property.livingArea?.value
    && !property.numberOfRooms
    && !property.constructionYear
  )

  return (
    <StyledPropertyCard>
      <Link 
        href={ property.brixelPageUrl || property.url || `https://page.brixel.io/${ locale }/${ property.stub }` }
        target="_blank"
        rel="noopener"
      >
        <StyledImage>
          <Image
            src={ property.coverImage }
            alt={ property.title }
            dateModified={ null }
            layout="fill"
            objectFit="cover"
            sizes="
              ( max-width: 750px ) 100vw,
              ( max-width: 1150px ) 50vw,
              ( max-width: 2000px ) 33vw,
              ( max-width: 2600px ) 25vw,
              20vw
            "
          />
          { showCountdown && (
            <DaysLeftTag countdownInDays={ property.companyExclusivity?.countdownInDays || 0 } />
          ) }
        </StyledImage>
        <StyledDetails>
          <h3>{ property.title }</h3>
          <StyledPropertyPrice $status={ property.status }>
            <p>
              <PropertyPriceByStatus property={ property } />
            </p>
          </StyledPropertyPrice>
          <PropertyDataList 
            property={ property } 
            showItems={ isBuildingLand ? ['lotSize'] : ['rooms', 'livingArea', 'constructionYear'] }
          />
        </StyledDetails>
      </Link>
    </StyledPropertyCard>
  )
}

function PropertyDataList( { 
  property, 
  showItems = ['rooms', 'livingArea', 'constructionYear'], 
}: {
  property: Property
  showItems?: ( 'rooms' | 'livingArea' | 'constructionYear' | 'lotSize' )[]
} ) {
  return (
    <StyledPropertyDataList>
      { showItems.map( showItem => {
        switch ( showItem ) {
          case 'rooms':
            return (
              <StyledPropertyDataItem>
                <p>
                  { !property.numberOfRooms && '—' }
                  { property.numberOfRooms > 0 && (
                  <>
                    { property.numberOfRooms }
                    &nbsp;
                    <FormattedMessage
                      description="Number of rooms label"
                      defaultMessage="Rooms"
                      id="V2MxOz"
                    />
                  </>
                  ) }
                </p>
              </StyledPropertyDataItem>
            )

          case 'livingArea':
            return (
              <StyledPropertyDataItem>
                <p>
                  { property.livingArea?.value
                    ? <>{ property.livingArea.value }&thinsp;{ property.livingArea.unit || 'm2' }</>
                    : '—'}
                </p>
              </StyledPropertyDataItem>
            )

          case 'constructionYear':
            return (
              <StyledPropertyDataItem>
                <p>
                  { property.constructionYear || '—' }
                </p>
              </StyledPropertyDataItem>
            )
          
          case 'lotSize':
            return (
              <StyledPropertyDataItem>
                <p>
                  { property.lotArea?.value
                    ? <>{ property.lotArea.value }&thinsp;{ property.lotArea.unit || 'm2' }</>
                    : '—'}
                </p>
              </StyledPropertyDataItem>
            )

          default:
            return null
        }
      } ) }
    </StyledPropertyDataList>
  )
}

const StyledPropertyCard = styled.article`
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  overflow: hidden;
  border-radius: var( --card-border-radius );
  background: white;

  a {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    color: inherit;
    text-decoration: none;
  }
`

const StyledImage = styled.div`
  position: relative;
  aspect-ratio: 368 / 187;
`

const StyledDetails = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: calc( 3 * var( --stack-basis ) );

  h3 {
    margin: 0;
    ${ p => p.theme.typo.semiBold }
    line-height: 1.2;
    font-size: ${ p => p.theme.typo.sizes.large };
  }
`

export const StyledPropertyDataList = styled.div`
  display: flex;
  justify-content: space-between;
  gap: calc( 3 * var( --stack-basis ) );
`

export const StyledPropertyDataItem = styled.div`
  margin: 0;
  margin-top: auto;
  padding: calc( 2 * var( --stack-basis ) ) 0;
  font-size: ${ p => p.theme.typo.sizes.small };
  line-height: 1;
  color: ${ p => p.theme.colors.grey600 };

  > * {
    margin: 0;
    font-weight: 400;
  }

  p {
    margin-top: var( --stack-basis );
    ${ p => p.theme.typo.mono }
    font-size: ${ p => p.theme.typo.sizes.dropped };

    span {
      margin-right: 0.3rem;
      font-size: ${ p => p.theme.typo.sizes.small };
    }
  }
`

const statusToColorMap = {
  ON_REQUEST: 'grey700',
  SOLD: 'red400',
  RESERVED: 'yellow400',
  ON_SALE: 'green400',
  TO_RENT: 'blue400',
}

export const StyledPropertyPrice = styled( StyledPropertyDataItem )<{
  $status: 'ON_REQUEST' | 'SOLD' | 'RESERVED' | 'ON_SALE' | 'TO_RENT'
}>`
  color: ${ p => p.theme.grey400 };
  border-bottom: 1px solid ${ p => p.theme.colors.grey200 };

  p {
    font-size: ${ p => p.theme.typo.sizes.normal };
    color: ${ p => p.theme.getColor( statusToColorMap[ p.$status ] ) };
  }
`
