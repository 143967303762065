import styled from 'styled-components'
import * as Icons from '../icons/small'

function FloorplanFooter( { sectionTitle, options } ) {
  return (
    <StyledFooter>
      <h3>{sectionTitle}</h3>

      <div>
        {options.map( option => {
          if ( option.typeHandle === 'list' ) {
            const Icon = Icons[ option.listIcon ]
            return (
              <div key={ option.id }>
                <h4>
                  {Icon && (
                    <Icon />
                  )}
                  {option.listTitle}
                </h4>
                <div 
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={ { __html: option.listContent } } 
                />
              </div>
            )
          }
          return null
        } )}
      </div>
    </StyledFooter>
  )
}

const StyledFooter = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: var(--grid-gutter);

  > h3 {
    flex: 1 0 calc(35% - var(--grid-gutter));
    min-width: 305px;
  }
  > div {
    flex: 1 0 calc(65% - var(--grid-gutter));
  }

  h4 {
    display: flex;
    align-items: center;
    gap: 16px;
  }
`

export default FloorplanFooter
