import { replaceFilterState } from './actions'
import { FilterGroupSelect } from './FilterGroupSelect'
import { FilterGroupProps } from './types'

export function FilterGroupControls( {
  filters,
  selected,
  onChange,
}: FilterGroupProps ) {
  const internalOnChange = ( key: string ) => ( selectedOptions: string[] | [number, number] ) => {
    onChange(
      replaceFilterState( {
        selectedState: selected,
        key,
        value: selectedOptions,
      } ),
    )
  }

  return (
    <div className="flex gap-12">
      {filters.map( filter => (
        <div
          key={ filter.key }
          className="w-max"
        >
          <FilterGroupControl
            filter={ filter }
            selected={ selected }
            onChange={ internalOnChange( filter.key ) }
          />
        </div>
      ) )}
    </div>
  )
}

FilterGroupControls.displayName = 'FilterGroup.Controls'

type FilterGroupControlProps = {
  filter: FilterGroupProps['filters'][number]
  selected: FilterGroupProps['selected']
  onChange: ( selectedOptions: string[] | [number, number] ) => void
}

function FilterGroupControl( {
  filter,
  selected,
  onChange,
}: FilterGroupControlProps ) {
  if ( filter.type === 'select' ) {
    return (
      <FilterGroupSelect
        key={ filter.key }
        options={ filter.options }
        label={ filter.label }
        selected={ ( selected[ filter.key ] as string[] ) || [] }
        onChange={ onChange }
      />
    )
  }

  return null
}
