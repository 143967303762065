import styled, { css } from 'styled-components'
import Stack from '../layout/Stack'
import Container from '../layout/Container'
import PageSegmentBackground from './PageSegmentBackground'
import PageSegmentHeading from './PageSegmentHeading'
import Accordion from '../Accordion'
import Image from '../Image'
import Background from '../layout/Background'
import Button from '../Button'

function AccordionSegment( {
  anchorId,
  title,
  subtitle,
  accordionStyle,
  accordionLayout,
  buttonLabel,
  buttonLink,
  buttonIcon,
  image,
  accordion,
  additionalBottomPadding,
} ) {
  const getThemeColors = () => {
    if ( accordionStyle === 'blue' ) {
      return {
        bgColor: 'blue400',
        textColor: 'realWhite',
      }
    }
    
    if ( accordionStyle === 'green' ) {
      return {
        bgColor: 'green700',
        textColor: 'green100',
      }
    }

    return {
      bgColor: 'transparent',
      textColor: 'black',
    }
  }

  const { bgColor, textColor } = getThemeColors()

  return (
    <PageSegmentBackground
      anchorId={ anchorId }
      color={ bgColor }
      segmentType="accordion"
      hasSectionTitle={ title || subtitle }
      additionalBottomPadding={ additionalBottomPadding }
    >
      <Container>
        <Stack
          $top={ 12 }
          $bottom={ 12 }
          $between={ 10 }
        >
          { image && (
            <>
              <Background $textColor={ textColor }>
                <PageSegmentHeading
                  title={ title }
                  subtitle={ subtitle }
                />
              </Background>
              <StyledAccordionWithImage $layout={ accordionLayout }>
                <StyledImage>
                  <Image
                    src={ image.url }
                    alt={ image.alt }
                    width={ image.width }
                    height={ image.height }
                    dateModified={ image.dateModified }
                    layout="responsive"
                  />
                </StyledImage>
                <Accordion
                  items={ accordion }
                  theme={ accordionStyle }
                />
              </StyledAccordionWithImage>
            </>
          ) }
          { !image && (
            <StyledAccordion>
              <Background $textColor={ textColor }>
                <Stack $between={ 5 }>
                  <PageSegmentHeading
                    title={ title }
                    subtitle={ subtitle }
                    align="left"
                  />
                  { buttonLabel && buttonLink && (
                    <Button
                      href={ buttonLink }
                      color="green"
                      icon={ buttonIcon }
                    >
                      { buttonLabel }
                    </Button>
                  ) }
                </Stack>
              </Background>
              <Accordion
                items={ accordion }
                theme={ accordionStyle }
              />
            </StyledAccordion>
          ) }
        </Stack>
      </Container>
    </PageSegmentBackground>
  )
}

const StyledAccordion = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: calc( 2 * var( --grid-gutter ) );

  > * {
    flex-grow: 1;
    max-width: 450px;

    &:last-child {
      max-width: max( calc( 50% + 17px ), 600px );
    }
  }
`

const StyledAccordionWithImage = styled.div<{ $layout: 'textLeft' | 'textRight' }>`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row-reverse;
  justify-content: space-evenly;
  gap: calc( 2 * var( --grid-gutter ) );

  > * {
    flex-grow: 1;
    max-width: 450px;
  }

  ${ p => p.$layout === 'textRight' && css`
    flex-direction: row;
  ` }
`

const StyledImage = styled.div`
  position: relative;
  /* margin-top: calc( 3 * var( --stack-basis ) ); */
`

export default AccordionSegment
