import styled from 'styled-components'
import PlusIcon from '@/components/icons/PlusIcon'
import {
  clearFilter,
  removeItemFromSelect,
} from './actions'
import { FilterGroupProps } from './types'

function defaultSelectSummaryLabel( value: string ) {
  return value
}

export function FilterGroupSummary( {
  filters,
  selected,
  onChange,
}: FilterGroupProps ) {
  const selectedFilterValues = getSelectedFilterDisplayValues( filters, selected )

  if ( !selectedFilterValues.length ) {
    return null
  }

  const removeSelectedFilterValue = (
    type: 'select' | 'range',
    key: string,
    value: string | undefined,
  ) => {
    if ( type === 'select' ) {
      onChange(
        removeItemFromSelect( {
          selectedState: selected,
          key,
          itemValue: value as string,
        } ),
      )
    }
    else if ( type === 'range' ) {
      onChange(
        clearFilter( {
          selectedState: selected,
          key,
        } ),
      )
    }
  }

  return (
    <StyledSummaryWrapper>
      {selectedFilterValues.map( selectedValue => {
        if ( !selectedValue?.values ) {
          return null
        }

        return selectedValue.values.map(
          value => value && (
          <StyledSummaryItem
            key={ `${ selectedValue.key }-${ value.value }` }
          >
            <span className="text-sm">{value.label}</span>
            <button
              type="button"
              onClick={ () => removeSelectedFilterValue(
                selectedValue.filterType,
                selectedValue.key,
                value.value,
              ) }
            >
              <PlusIcon />
            </button>
          </StyledSummaryItem>
          ),
        )
      } )}
    </StyledSummaryWrapper>
  )
}

FilterGroupSummary.displayName = 'FilterGroup.Summary'

function getSelectedFilterDisplayValues(
  filters: FilterGroupProps['filters'],
  selected: FilterGroupProps['selected'],
) {
  return filters
    .map( filter => {
      if ( !selected[ filter.key ]?.length ) {
        return null
      }

      if ( filter.type === 'select' ) {
        return {
          filterType: filter.type,
          key: filter.key,
          values: filter.options
            .filter( o => ( selected[ filter.key ] as string[] ).includes( o.value ) )
            .map( v => ( {
              ...v,
              label: filter.summaryLabel
                ? filter.summaryLabel( v.label )
                : defaultSelectSummaryLabel( v.label ),
            } ) ),
        }
      }

      return null
    } )
    .filter( Boolean )
}

const StyledSummaryWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
`

const StyledSummaryItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  padding: 8px 8px 8px 16px;
  border-radius: 9999px;
  border: 1px solid #e2e8f0;
  background-color: #fff;

  > span {
    font-size: 14px;
  }

  > button {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    border-radius: 9999px;
    background-color: #fff;
    padding: 4px;
    transition: background-color 0.2s ease-in-out;

    > svg {
      width: 16px;
      height: 16px;
      transform: rotate(45deg);

      path {
        fill: black;
      }
    }

    &:hover {
      background-color: ${ p => p.theme.colors.grey100 };
    }

    &:focus {
      outline: none;
      box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
    }
  }
`
