'use client'

import { Middleware, useFloating } from '@floating-ui/react-dom'
import { Listbox } from '@headlessui/react'
import { ReactNode } from 'react'
import { createPortal } from 'react-dom'
import styled from 'styled-components'
import ChevronIcon from '@/components/icons/ChevronIcon'
import TickIcon from '@/components/icons/TickIcon'
import usePreventBodyScroll from '@/hooks/usePreventBodyScroll'
import { getDisplayValue } from './displayValueUtils'
import { SelectedValues, SelectProps } from './types'

export type { SelectProps } from './types'

export function Select<TSelectedValues extends SelectedValues>( {
  label = undefined,
  displayValue = undefined,
  placeholder = undefined,
  options,
  selected,
  onChange,
}: SelectProps<TSelectedValues> ) {
  const { refs } = useFloating( {
    placement: 'bottom-end',
    middleware: [floatingUISelectPositionMiddleware],
  } )

  const computedDisplayValue = getDisplayValue<typeof selected>(
    selected,
    options,
    placeholder,
    displayValue,
  )

  return (
    <Listbox
      value={ selected }
      onChange={ onChange }
      multiple={ Array.isArray( selected ) }
    >
      {( { open } ) => (
        <StyledSelectWrapper>
          {label && (
            <StyledSelectLabel>
              {label}
            </StyledSelectLabel>
          )}
          <Listbox.Button
            ref={ refs.setReference }
          >
            <span>
              {computedDisplayValue}
            </span>
            <div>
              <ChevronIcon />
            </div>
          </Listbox.Button>
          <Portal open={ open }>
            <StyledOptionsWrapper>
              <StyledOptionsList
                ref={ refs.setFloating }
              >
                {options.map( option => (
                  <SelectionOption
                    key={ option.value }
                    label={ option.label }
                    value={ option.value }
                    isSelected={ selected.includes( option.value ) }
                  />
                ) )}
              </StyledOptionsList>
            </StyledOptionsWrapper>
          </Portal>
        </StyledSelectWrapper>
      )}
    </Listbox>
  )
}

type SelectOptionProps = {
  label: string
  value: string
  isSelected: boolean
}

function SelectionOption( { label, value, isSelected }: SelectOptionProps ) {
  return (
    <StyledOption
      value={ value }
    >
      <span>{label}</span>
      <div>
        { isSelected && <TickIcon /> }
      </div>
    </StyledOption>
  )
}

type PortalProps = {
  open: boolean
  children: ReactNode
}

function Portal( { open, children }: PortalProps ) {
  usePreventBodyScroll( open, 'floating-ui-select' )

  if ( !open ) {
    return null
  }

  return createPortal( children, document.body )
}

const floatingUISelectPositionMiddleware: Middleware = {
  name: 'floatingUISelectPositionMiddleware',
  fn( state ) {
    const refEl = state.elements.reference
    const floatingEl = state.elements.floating
    const refBoundingRect = refEl.getBoundingClientRect()
    const floatingBoundingRect = floatingEl.getBoundingClientRect()
    const width = Math.max( refBoundingRect.width, floatingBoundingRect.width )
    Object.assign( floatingEl.style, {
      width: `${ width }px`,
      transform: `translate(${ refBoundingRect.right - width }px, ${ refBoundingRect.bottom }px)`,
    } )
    return {}
  },
}

const StyledSelectWrapper = styled.div`
  position: relative;
  width: 100%;
`

const StyledSelectLabel = styled( Listbox.Label )`
  display: block;
  padding-bottom: 8px;
  text-overflow: ellipsis;
  font-size: 14px;
  font-weight: 500;
  color: ${ p => p.theme.colors.black };
`

const StyledOptionsWrapper = styled.div`
  position: fixed;
  inset: 0;
  z-index: 10;
  overflow: auto;
` 

const StyledOptionsList = styled( Listbox.Options )`
  padding: 0;
  margin: 0;
  position: absolute;
  z-index: 10;
  margin-top: 8px;
  width: max-content;
  overflow: hidden;
  border-radius: 8px;
  font-size: 14px;
  background: white;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05), 0 4px 11px rgba(0, 0, 0, 0.1);

  &:focus {
    outline: none;
  }

  &:focus-visible {
    outline: none;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05), 0 4px 11px rgba(0, 0, 0, 0.1), 0 0 0 3px rgba(66, 153, 225, 0.5);
  }
`

const StyledOption = styled( Listbox.Option )`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  padding: 8px 12px;
  cursor: pointer;
  background: white;

  &[data-headlessui-state="active"] {
    background: ${ p => p.theme.colors.green100 };
  }

  > span {
    width: max-content;
  }

  > div {
    width: 16px;
    height: 16px;

    svg {
      width: 100%;
      height: 100%;
    }
  }
`
