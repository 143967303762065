import styled, { css } from 'styled-components'
import { textLink } from '../../styles/links'
import Image from '../Image'

function Postcard( {
  number = null,
  tag = null,
  heading,
  bodyText,
  image,
  cardColor,
  layout,
} ) {
  return (
    <StyledPostcard 
      $layout={ layout }
      $color={ cardColor }
    >
      <div>
        { number && <StyledNumber $color={ cardColor }>{ number }</StyledNumber> }
        { tag && <StyledTag $color={ cardColor }>{ tag }</StyledTag> }
        <h3>{ heading }</h3>
        <div 
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={ { __html: bodyText } } 
        />
      </div>
      <div>
        <Image
          src={ image.url }
          alt={ image.alt }
          dateModified={ image.dateModified }
          layout="fill"
          objectFit="contain"
        />
      </div>
    </StyledPostcard>
  )
}

const colorMap = {
  blue: 'blue400',
  grey: 'grey700',
  green: 'green400',
  red: 'red400',
}

const coloredTextLink = ( theme, cardColor ) => textLink( theme, colorMap[ cardColor ] )

const headingColorMap = {
  blue: 'blue700',
  grey: 'grey700',
  green: 'green700',
  red: 'red700',
}

const coloredHeading = ( theme, color ) => css`
  color: ${ theme.getColor( headingColorMap[ color ] ) };
`

const StyledTag = styled.div<{ $color: string }>`
  height: 32px;
  padding: 0 1.5rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-bottom: calc( 4 * var( --stack-basis ) );
  border-radius: 100rem;
  border: 1px solid;
  color: ${ p => p.theme.getColor( colorMap[ p.$color ] ) };
  border-color: --currentColor;
  ${ p => p.theme.typo.mono }
  font-size: ${ p => p.theme.typo.sizes.small };
`

const StyledNumber = styled( StyledTag )`
  width: 32px;
  padding: 0;
`

export const StyledPostcard = styled.div.attrs<{
  $layout: 'image-left' | 'image-right',
  $color: 'blue' | 'grey' | 'green' | 'red',
}>( p => ( {
  className: `bx-c-postcard--${ p.$color }`,
} ) )<{
  $layout: 'image-left' | 'image-right',
  $color: 'blue' | 'grey' | 'green' | 'red',
}>`
  display: flex;
  flex-wrap: wrap;
  min-height: 423px;

  flex-direction: ${ p => p.$layout === 'image-right' ? 'row' : 'row-reverse' };

  > div {
    position: relative;
    box-sizing: border-box;
    flex: 1 0 50%;
    min-width: min( 250px, 100% );
    word-wrap: break-word;
    word-break: break-all;
    word-break: break-word;
    hyphens: auto;

    &:first-child {
      padding: calc( 8 * var( --stack-basis ) ) calc( 3 * var( --grid-gutter ) );

      @media ( max-width: 900px ) {
        padding: calc( 6 * var( --stack-basis ) ) calc( 2 * var( --grid-gutter ) );
      }
      
      @media ( max-width: 600px ) {
        padding-left: calc( 1 * var( --grid-gutter ) );
        padding-right: calc( 1 * var( --grid-gutter ) );
      }

      h3 {
        ${ p => p.theme.typo.semiBold }
        font-size: ${ p => p.theme.typo.sizes.veryLarge };
        line-height: 1.2;
        margin: 0 0 calc( 2 * var( --stack-basis ) ) 0;
        ${ p => coloredHeading( p.theme, p.$color ) }
      }

      h4 {
        margin: calc( 3 * var( --stack-basis ) ) 0 var( --stack-basis );
        font-size: ${ p => p.theme.typo.sizes.normal };
      }

      p {
        margin: 0 0 calc( 3 * var( --stack-basis ) ) 0;
        font-size: ${ p => p.theme.typo.sizes.dropped };

        &:first-child {
          margin-top: 0;
        }
        
        &:last-child {
          margin-bottom: 0;
        }
      }

      a {
        ${ p => coloredTextLink( p.theme, p.$color ) }
      }
    }

    &:last-child {
      min-height: 250px
    }
  }

  ${ p => p.$color === 'blue' && css`
    > div {
      &:first-child {
        background-color: ${ p.theme.colors.blue100 };
      }
      
      &:last-child {
        background-color: ${ p.theme.colors.blue200 };
      }
    }
  ` }
  
  ${ p => p.$color === 'red' && css`
    > div {
      &:first-child {
        background-color: ${ p.theme.colors.red100 };
      }
      
      &:last-child {
        background-color: ${ p.theme.colors.red200 };
      }
    }
  ` }
  
  ${ p => p.$color === 'green' && css`
    > div {
      &:first-child {
        background-color: ${ p.theme.colors.green100 };
      }
      
      &:last-child {
        background-color: ${ p.theme.colors.green200 };
      }
    }
  ` }
  
  ${ p => p.$color === 'grey' && css`
    > div {
      &:first-child {
        background-color: ${ p.theme.colors.grey100 };
      }
      
      &:last-child {
        background-color: ${ p.theme.colors.grey200 };
      }
    }
  ` }

  ${ StyledNumber } {
    color: ${ p => p.theme.getColor( colorMap[ p.$color ] ) };
  }
`

export default Postcard
