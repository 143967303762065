import styled from 'styled-components'
import Image from '../Image'
import Link from '../Link'

function ContactCard( { contactCard } ) {
  const image = contactCard.icon[ 0 ]

  return (
    <StyledContactCard>
      <StyledImage>
        <Image
          src={ image.url }
          alt={ image.alt }
          dateModified={ image.dateModified }
          layout="fill"
          objectFit="contain"
        />
      </StyledImage>
      <p>
        { contactCard.text }
        <Link href={ contactCard.linkDestination }>
          { contactCard.linkText }
        </Link>
      </p>
    </StyledContactCard>
  )
}

export const StyledContactCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  p {
    margin: 0;
    font-size: ${ p => p.theme.typo.sizes.medium };
    word-wrap: break-word;
    word-break: break-all;
    word-break: break-word;
    hyphens: auto;

    a {
      display: block;
      color: ${ p => p.theme.colors.green400 };
      text-decoration: underline;
      text-decoration-thickness: 1px;
      text-underline-offset: 3px;
      transition: color .3s ease-out;

      &:hover {
        color: ${ p => p.theme.colors.green700 };
      }
    }
  }
`

const StyledImage = styled.div`
  overflow: hidden;
  position: relative;
  width: 100px;
  height: 78px;
  margin-bottom: calc( 3 * var( --stack-basis ) );
`

export default ContactCard
