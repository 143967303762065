import React from 'react'
import styled from 'styled-components'
import Stack from '../layout/Stack'
import Container from '../layout/Container'
import PageSegmentBackground from './PageSegmentBackground'
import PageSegmentHeading from './PageSegmentHeading'

function ColumnsSegment( {
  anchorId,
  title,
  columns,
} ) {
  return (
    <PageSegmentBackground
      anchorId={ anchorId }
      color="green100"
      segmentType="contact"
      hasSectionTitle={ title }
    >
      <Container>
        <Stack
          $top={ 12 }
          $bottom={ 12 }
          $between={ 10 }
        >
          <PageSegmentHeading
            title={ title }
            align="left"
          />
          <StyledColumns>
            {columns?.map( ( { id, columnHeading, columnContent } ) => (
              <div key={ id }>
                <h3>{columnHeading}</h3>
                <div 
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={ { __html: columnContent } } 
                />
              </div>
            ) )}
          </StyledColumns>
        </Stack>
      </Container>
    </PageSegmentBackground>
  )
}

const StyledColumns = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: var(--grid-gutter);
  > div {
    flex: 1 0 calc(50% - var(--grid-gutter));
  }

  a {
    color: ${ p => p.theme.colors.green700 };
  }
`

export default ColumnsSegment
