import { SelectedState } from './types'

type RemoveItemFromSelectParams = {
  selectedState: SelectedState
  key: string
  itemValue: string
}

export function removeItemFromSelect( {
  selectedState,
  key,
  itemValue,
}: RemoveItemFromSelectParams ) {
  const newValues = ( selectedState[ key ] as string[] ).filter(
    v => v !== itemValue,
  )

  if ( newValues.length === 0 ) {
    return clearFilter( { selectedState, key } )
  }

  return {
    ...selectedState,
    [ key ]: ( selectedState[ key ] as string[] ).filter( v => v !== itemValue ),
  }
}

type ClearFilterParams = {
  selectedState: SelectedState
  key: string
}

export function clearFilter( { selectedState, key }: ClearFilterParams ) {
  const newState = { ...selectedState }
  delete newState[ key ]
  return newState
}

type ReplaceFilterStateParams = {
  selectedState: SelectedState
  key: string
  value: string[] | [number, number]
}

export function replaceFilterState( {
  selectedState,
  key,
  value,
}: ReplaceFilterStateParams ) {
  if ( !value || ( Array.isArray( value ) && value.length === 0 ) ) {
    return clearFilter( { selectedState, key } )
  }

  return {
    ...selectedState,
    [ key ]: value,
  }
}
