import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'
import ClockIcon from '../icons/ClockIcon'

export default function DaysLeftTag( { countdownInDays } ) {
  const daysRemaining = Math.max( countdownInDays, 0 )
  const daysRemainingTagColor = daysRemaining <= 3 ? 'red' : 'green'

  if ( !daysRemaining ) {
    return null
  }

  return (
    <StyledDaysLeftTag $color={ daysRemainingTagColor }>
      <ClockIcon color={ `${ daysRemainingTagColor }400` } />
      <FormattedMessage
        description="[x] days left"
        defaultMessage="{ daysRemaining } {daysRemaining, plural, one {day left} other {days left} }"
        values={ { daysRemaining } }
        id="wNgL3D"
      />
    </StyledDaysLeftTag>
  )
}

export const StyledDaysLeftTag = styled.span<{ $color: 'red' | 'green' }>`
  position: absolute;
  top: calc( 2 * var( --stack-basis ) );
  left: calc( 2 * var( --stack-basis ) );
  padding: 0.5rem 1rem 0.5rem 0.5rem;
  display: flex;
  gap: 0.5em;
  align-items: center;
  border-radius: var( --card-border-radius );
  font-size: ${ p => p.theme.typo.sizes.small };
  background: ${ p => p.theme.colors[ `${ p.$color }100` ] };
  color: ${ p => p.theme.colors[ `${ p.$color }400` ] };
`
