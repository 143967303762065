import { useRef } from 'react'
import styled, { css } from 'styled-components'
import useWindowResize from '../../hooks/useWindowResize'
import Image from '../Image'

function Logo( {
  label, 
  image,
  href,
  hasBorder,
  setPosition, 
} ) {
  const ref = useRef( null )

  useWindowResize( () => {
    if ( ref.current ) {
      setPosition( ref.current.offsetTop )
    }
  }, [], true )

  return (
    <StyledLogo 
      ref={ ref }
      $hasBorder={ hasBorder }
    >
      <h5>{ label }</h5>
      <LogoLink href={ href }>
        <Image
          src={ image.url }
          alt={ image.alt }
          dateModified={ image.dateModified }
          layout="fill"
          objectFit="contain"
        />
      </LogoLink>
    </StyledLogo>
  )
}

function LogoLink( { href, children } ) {
  if ( href ) {
    return (
      <StyledImage 
        as="a"
        href={ href }
        target="_blank"
        rel="noopener"
      >
        { children }
      </StyledImage>
    )
  }

  return (
    <StyledImage>
      { children }
    </StyledImage>
  )
}

const StyledImage = styled.div`
  position: relative;
  width: 80%;
  margin-top: auto;
  aspect-ratio: 1.6/1;
`

const StyledLogo = styled.div<{
  $hasBorder: boolean,
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  width: 235px;
  padding: var( --grid-gutter );
  
  ${ p => p.$hasBorder && css`
    border-right: 1px solid ${ p.theme.colors.grey300 };
  ` }

  a {
    display: block;
  }

  h5 {
    margin: 0 0 var( --stack-basis );
    text-align: center;
    ${ p => p.theme.typo.regular };
    font-size: ${ p => p.theme.typo.sizes.normal };
    color: ${ p => p.theme.colors.grey400 };
  }
`

export default Logo
