export default function ParkScene() {
  return (
    <svg width="430" height="109" viewBox="0 0 430 109" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M235.952 26.3875C234.469 23.1775 231.773 20.6431 228.451 19.3829C228.855 9.03462 237.362 0.769531 247.798 0.769531C258.491 0.769531 267.16 9.44829 267.16 20.1541C267.16 24.3812 265.809 28.2923 263.515 31.478C260.266 30.0411 257.083 30.3194 254.556 31.6692M235.952 26.3875C237.507 23.0369 240.894 20.7101 244.828 20.7101C249.147 20.7101 252.812 23.5052 254.118 27.3858C254.451 28.3731 254.6 31.2954 254.556 31.6692M235.952 26.3875C233.805 21.741 229.117 18.5103 223.667 18.5103C217.59 18.5103 212.449 22.5178 210.734 28.0356C210.338 29.3133 210.121 31.9996 210.124 32.0686C207.902 30.5004 205.026 30.2317 201.955 30.7726C200.356 31.0521 198.884 31.8616 197.807 33.0777C197.771 33.1176 197.735 33.1612 197.698 33.2011C194.431 36.9619 197.176 42.839 202.155 42.839H261.971C266.623 42.839 269.336 37.4556 266.457 33.8001C266.428 33.7602 266.395 33.7239 266.362 33.6839C265.608 32.7474 264.644 31.9814 263.545 31.4914C260.285 30.0393 257.091 30.3152 254.556 31.6692" stroke="#06262D" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M140.479 94.661L147.264 81.0737H194.766L201.551 94.661" stroke="#06262D" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M154.05 81.0736L160.836 67.4863" stroke="#06262D" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M154.05 67.4863H208.337" stroke="#06262D" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M157.445 74.2808H198.161" stroke="#06262D" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M187.979 94.661L194.765 81.0736L201.551 67.4863" stroke="#06262D" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M154.051 94.661L147.265 81.0737" stroke="#06262D" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M1.24609 95.269H267.641" stroke="#06262D" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M344 95L429 95" stroke="#06262D" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M309 95H289" stroke="#06262D" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M373.985 18.112C356.616 18.112 342.535 32.2087 342.535 49.5978C342.535 66.9869 356.616 81.0835 373.985 81.0835C391.354 81.0835 405.435 66.9869 405.435 49.5978C405.435 32.2087 391.354 18.112 373.985 18.112Z" fill="#009877" />
      <path d="M373.491 36.9556V95.1081" stroke="#06262D" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M386.398 43.7207L373.884 56.3398" stroke="#06262D" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M360.563 56.4033L373.491 69.2615" stroke="#06262D" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M328.719 80.8594C330.159 82.9175 332.04 84.6579 334.261 85.9431L341.935 90.3819L345.113 92.2171V92.2361L340.964 99.4349L339.661 101.692L338.539 103.636C338.344 103.968 337.838 103.836 337.833 103.452L337.819 100.639C337.819 100.639 337.819 100.63 337.814 100.63C337.814 100.63 334.347 98.6287 329.866 96.0441L329.458 95.807C327.45 94.6499 325.607 93.2746 323.949 91.7239" stroke="#06262D" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M316.626 43.8848V44.9992" stroke="#06262D" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M315.551 49.343C317.607 49.2671 319.208 47.5362 319.132 45.478L315.551 49.343Z" fill="#E6F5ED" />
      <path d="M315.551 49.343C317.607 49.2671 319.208 47.5362 319.132 45.478" stroke="#06262D" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M330.457 73.8555C330.016 77.1893 329.055 80.4614 327.582 83.5439L316.142 107.469V107.336L316.047 107.559C316.047 107.564 316.038 107.568 316.029 107.568L308.345 104.415L305.939 103.428L303.864 102.575C303.509 102.428 303.575 101.906 303.954 101.849L306.74 101.46L306.749 101.451L307.616 99.2266L317.307 74.3581" stroke="#06262D" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M325.754 41.4375L336.184 43.14L337.165 37.1126C332.708 36.3823 328.425 38.1748 325.754 41.4375Z" fill="#E6F5ED" stroke="#06262D" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M305.413 71.3471C304.423 69.9291 302.557 69.4312 300.999 70.1852L300.388 70.4793" fill="#E6F5ED" />
      <path d="M305.413 71.3471C304.423 69.9291 302.557 69.4312 300.999 70.1852L300.388 70.4793" stroke="#06262D" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M283.511 98.4822L284.127 98.3257C284.127 98.3257 284.131 98.3352 284.136 98.3446L281.218 108H277.239L278.755 99.6962L283.511 98.4869V98.4822Z" fill="#E6F5ED" stroke="#06262D" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M266.543 84.563V85.2601" stroke="#06262D" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M259.917 87.1858C261.011 87.1858 262.058 86.7496 262.83 85.9766L259.917 87.1858Z" fill="#E6F5ED" />
      <path d="M259.917 87.1858C261.011 87.1858 262.058 86.7496 262.83 85.9766" stroke="#06262D" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M259.268 82.9321L260.277 82.8041L261.821 82.6855C261.963 84.113 261.006 85.4076 259.623 85.7064L259.268 82.9321Z" fill="#E6F5ED" stroke="#06262D" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M271.03 86.1331C273.664 86.1331 273.664 80.855 273.664 80.855H268.392C268.392 80.855 268.392 86.1331 271.025 86.1331H271.03Z" fill="#E6F5ED" stroke="#06262D" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M272.1 86.1337C272.228 87.9832 273.644 89.4912 275.482 89.7283L286.358 91.2601C288.072 91.5019 289.806 90.6578 290.545 89.0929C290.55 89.0834 290.554 89.0692 290.564 89.0597C290.829 88.4859 290.967 87.8883 290.99 87.2908C292.212 88.6186 292.587 90.6057 291.777 92.3461C291.246 93.4842 290.313 94.3046 289.223 94.7125C289.223 94.7125 289.223 94.7125 289.223 94.7077C289.28 94.9923 289.313 95.2009 289.328 95.2626C290.014 98.5252 291.668 101.537 294.107 103.879L291.265 106.943L290.19 105.947C287.793 103.732 285.747 101.162 284.131 98.3213L283.515 98.4778L278.76 99.6871L276.396 100.289L272.138 101.371L271.981 101.409L269.532 107.986H265.084L267.552 94.5939C267.358 94.3615 267.173 94.1244 266.998 93.8778C266.198 92.7444 265.629 91.4356 265.35 90.0224H260.187L259.268 82.8758L268.4 81.9463" stroke="#06262D" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M302.122 71.3472H300.199C297.537 71.3472 295.377 73.5096 295.377 76.1748V78.674C295.377 81.3391 293.217 83.5016 290.555 83.5016H284.624C281.171 83.5016 277.798 84.5544 274.961 86.5224L273.028 87.8597" fill="#E6F5ED" />
      <path d="M302.122 71.3472H300.199C297.537 71.3472 295.377 73.5096 295.377 76.1748V78.674C295.377 81.3391 293.217 83.5016 290.555 83.5016H284.624C281.171 83.5016 277.798 84.5544 274.961 86.5224L273.028 87.8597" stroke="#06262D" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M318.724 40.2143C316.829 41.4331 313.869 41.3572 312.111 40.0483L318.724 40.2143Z" fill="#E6F5ED" />
      <path d="M318.724 40.2143C316.829 41.4331 313.869 41.3572 312.111 40.0483" stroke="#06262D" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M325.8 45.4779C321.731 45.3735 318.557 43.0119 318.704 40.1997L325.8 45.4779Z" fill="#E6F5ED" />
      <path d="M325.8 45.4779C321.731 45.3735 318.557 43.0119 318.704 40.1997" stroke="#06262D" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M316.01 52.876L315.082 46.4313L313.04 46.5024L313.329 42.6849C313.537 39.8964 315.532 37.4826 318.279 36.9705C321.438 36.3777 324.451 38.3125 325.285 41.3429L326.815 50.8037" stroke="#06262D" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M328.284 50.519L316.011 52.8759" stroke="#06262D" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M330.182 56.3328C331.329 55.3844 332.162 54.0945 332.546 52.6433C332.906 51.2871 331.831 50.0114 330.486 50.092L326.81 50.7986L315.716 52.9326L315.849 54.3031C315.948 55.3132 315.73 56.3281 315.223 57.2054C313.078 60.9091 310.321 64.186 307.071 66.9128L311.334 72.6557L312.907 71.4417C313.637 73.1537 315.328 74.3487 317.303 74.3487L330.533 73.865C333.2 73.7701 335.028 71.1429 334.199 68.6058L330.182 56.3281V56.3328Z" fill="#00C1DE" stroke="#06262D" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M322.415 59.1641C320.88 62.1043 319.028 64.8406 316.906 67.3303C314.206 70.5028 311.061 73.2771 307.555 75.5771C307.555 75.5771 301.483 79.9162 300.668 78.6738C300.256 78.0478 302.539 76.5729 303.453 76.0181C302.198 76.7342 299.858 78.0146 299.361 77.1183C298.977 76.4259 301.407 74.8894 302.402 74.3014C301.246 74.8942 298.584 76.1177 298.228 75.4632C297.831 74.7377 301.369 72.8503 301.369 72.8503" stroke="#06262D" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M272.445 87.4849L265.79 91.6011C266.07 92.3504 266.439 93.0618 266.88 93.7162L273.904 89.1589C273.27 88.7558 272.758 88.1725 272.445 87.4849Z" fill="#FF595A" stroke="#FF595A" strokeMiterlimit="10" />
      <path d="M306.797 66.5381L311.292 72.5987" stroke="#06262D" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M61.6257 67.4868L59.0869 76.9835L60.8779 77.4318L63.4075 67.9813L61.6257 67.4868Z" fill="#FF595A" stroke="#FF595A" strokeMiterlimit="10" />
      <path d="M97.2789 88.6245H86.8145C86.8329 89.2438 86.8468 89.8584 86.856 90.473H97.3205L97.2789 88.6245Z" fill="#FF595A" stroke="#FF595A" strokeMiterlimit="10" />
      <path d="M96.084 32.9845C97.1872 32.8412 97.9027 32.5593 97.9258 32.1988C97.9489 31.7968 97.0949 31.3855 95.7886 31.1082C97.118 30.9835 98.0135 30.6831 98.0412 30.2764C98.0689 29.8281 97.0118 29.3752 95.4562 29.098C96.6702 28.964 97.4734 28.6728 97.4965 28.2892C97.5334 27.6608 95.4424 26.9768 92.8205 26.8659C88.7215 26.6857 86.6581 27.938 86.6581 27.938C85.2364 28.6682 84.1101 29.976 83.6301 31.6027L80.9251 40.7436L80.1542 43.3454L79.7434 43.2252C69.5743 40.2445 58.7452 45.559 55.0432 55.4994C54.6508 56.5484 54.4893 57.2508 54.6693 57.4033C55.1263 57.7869 56.6311 55.6657 57.1988 54.82C56.4603 55.9753 55.1263 58.1289 55.8187 58.5679C56.3495 58.9052 57.9097 56.6639 58.5098 55.7489C57.882 56.8118 56.5618 59.2565 57.0696 59.5707C57.6328 59.9219 58.4406 58.8683 59.1745 57.6852C59.3084 57.4726 59.9315 56.4606 59.9038 56.5068C59.1699 57.6898 58.2744 59.4274 58.8191 59.7648C59.5899 60.2408 61.3994 57.297 62.1933 55.9014C62.5534 56.5669 63.2181 57.3432 63.6243 57.2508C64.2382 57.1075 64.2844 55.5086 63.9659 54.1499C66.1631 50.7856 70.3083 49.2745 74.1534 50.4344L76.3737 51.1045L70.4652 69.9409L57.1942 66.2577L53.9215 78.1344C53.8199 78.4902 54.2492 78.749 54.5169 78.4949L56.5711 76.3598L69.879 79.6779C74.3704 80.7962 78.9494 78.1945 80.2881 73.7534L82.4161 66.0359" stroke="#06262D" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M91.8604 33.6777V34.7406" stroke="#06262D" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M90.8594 37.421L90.8732 37.0005" stroke="#06262D" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M87.4424 34.3198C88.4394 34.3198 89.2472 35.1286 89.2472 36.1267C89.2472 37.1249 88.4394 37.9337 87.4424 37.9337" stroke="#06262D" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M66.5223 68.8408L64.002 78.2636" stroke="#06262D" strokeLinejoin="round" />
      <path d="M81.3682 70.3096L82.4298 71.7607C86.4919 77.2092 86.732 83.9978 86.852 90.5508L86.9304 94.9041H99.726C100.095 94.9041 100.225 94.4142 99.906 94.2294L97.3672 92.8522L97.2056 85.8186C97.021 77.6806 94.9023 70.5407 90.7248 63.886C88.4537 60.2676 89.8246 55.3505 89.8246 55.3505C101.355 59.1307 110.306 52.6609 113.939 42.91C114.331 41.861 114.493 41.1586 114.313 41.0061C113.856 40.6225 112.351 42.7437 111.783 43.5894C112.522 42.434 113.856 40.2805 113.163 39.8415C112.632 39.5042 111.072 41.7455 110.472 42.6605C111.1 41.5976 112.42 39.1529 111.912 38.8387C111.349 38.4875 110.541 39.5411 109.807 40.7242C109.673 40.9368 109.05 41.9488 109.078 41.9026C109.812 40.7195 110.707 38.982 110.163 38.6446C109.392 38.1686 107.582 41.1124 106.788 42.508C106.428 41.8425 105.764 41.0661 105.357 41.1586C104.744 41.3018 104.697 42.9008 105.016 44.2595C104.111 45.6458 102.874 46.718 101.462 47.4204C99.2967 48.4925 96.7625 48.548 94.4822 47.7485L91.0294 46.5377L91.7126 43.7511L93.0928 38.1316L95.0269 38.1963C95.2669 38.2056 95.4469 37.9791 95.3869 37.7434L94.833 32.4336" stroke="#06262D" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M82.5362 37.3195C84.0658 37.3195 85.3058 36.0781 85.3058 34.5467C85.3058 33.0153 84.0658 31.7739 82.5362 31.7739C81.0066 31.7739 79.7666 33.0153 79.7666 34.5467C79.7666 36.0781 81.0066 37.3195 82.5362 37.3195Z" fill="#E6F5ED" stroke="#06262D" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M82.5361 34.5463V29.4213C82.5361 26.2557 85.098 23.6909 88.2599 23.6909C90.4941 23.6909 92.4328 24.9756 93.3745 26.8426" stroke="#06262D" strokeMiterlimit="10" strokeLinecap="round" />
      <path d="M97.1966 85.3896H86.6582" stroke="#06262D" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}
