import styled from 'styled-components'
import Testimonial from './Testimonial'

function Testimonials( { testimonials } ) {
  return (
    <StyledTestimonials>
      { testimonials.map( t => (
        <Testimonial
          key={ t.id }
          testimonial={ t }
        />
      ) ) }
    </StyledTestimonials>
  )
}

const StyledTestimonials = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: calc( 2 * var( --grid-gutter ) );

  > * {
    max-width: 305px;
  }
`

export default Testimonials
