import Stack from '../layout/Stack'
import Container from '../layout/Container'
import PageSegmentBackground from './PageSegmentBackground'
import PageSegmentBodyText from './PageSegmentBodyText'
import PageSegmentHeading from './PageSegmentHeading'
import Packages from '../packages/Packages'

function PackagesSegment( {
  anchorId,
  title,
  subtitle,
  bodyText,
  packages,
  additionalBottomPadding,
} ) {
  return (
    <PageSegmentBackground 
      anchorId={ anchorId }
      color="ivory"
      segmentType="packages"
      hasSectionTitle={ title || subtitle }
      additionalBottomPadding={ additionalBottomPadding }
    >
      <Container $containerType="full">
        <Container>
          <Stack
            $top={ 12 }
            $bottom={ 12 }
            $between={ 3 }
          >
            <PageSegmentHeading
              title={ title }
              subtitle={ subtitle }
            />
            <Packages
              packages={ packages }
            />
            <PageSegmentBodyText
              html={ bodyText }
              bodyStyle="small"
            />
          </Stack>
        </Container>
      </Container>
    </PageSegmentBackground>
  )
}

export default PackagesSegment
