import styled from 'styled-components'
import { getEmbedUrl } from '../lib/video'

function VideoEmbed( { url, autoplay = false } ) {
  const embedUrl = getEmbedUrl( url )

  if ( !embedUrl ) {
    return null
  }

  return (
    <StyledVideoEmbed>
      <div>
        <iframe
          width="853"
          height="480"
          src={ `${ embedUrl }${ autoplay ? '?autoplay=1' : '' }` }
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="Embedded youtube"
        />
      </div>
    </StyledVideoEmbed>
  )
}

const StyledVideoEmbed = styled.div`
  z-index: 1;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  div,
  iframe {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    Height: 100%;
  }
`

export default VideoEmbed
