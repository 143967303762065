import styled, { css } from 'styled-components'
import { TextSizes } from '../../styles/fonts'

type Align = 'left' | 'center'

type PageSegmentHeadingProps = {
  id?: string,
  title?: string,
  subtitle?: string,
  align?: Align,
  size?: TextSizes,
}

function PageSegmentHeading( { 
  id = null,
  title = null, 
  subtitle = null,
  align = 'center',
  size = 'veryLarge',
}: PageSegmentHeadingProps ) {
  if ( !title && !subtitle ) {
    return null
  }

  return (
    <StyledPageSegmentHeading 
      id={ id }
      $textSize={ size }  
      $textAlign={ align }
    >
      { title && <h2>{ title }</h2> }
      { subtitle && <p>{ subtitle }</p> }
    </StyledPageSegmentHeading>
  )
}

const StyledPageSegmentHeading = styled.hgroup<{
  $textSize: TextSizes,
  $textAlign: Align,
}>`
  width: calc( 100vw - ( 2 * var( --container-margin-width ) ) );
  max-width: min( 60rem, 100% );
  ${ p => p.$textAlign === 'center' && css`
    margin: 0 auto;
  ` }
  text-align: ${ p => p.$textAlign };
  color: inherit;

  h2 {
    margin: 0;
    ${ p => p.theme.typo.semiBold }
    font-size: ${ p => p.theme.typo.sizes[ p.$textSize ] };
    line-height: 1.1;
    color: inherit;

    @media ( max-width: 500px ) {
      font-size: ${ p => p.theme.typo.sizes.medium };
    }
  }

  h2 + p {
    margin: var( --stack-basis ) 0 0;
    color: inherit;
  }
`

export default PageSegmentHeading
