import React from 'react'
import styled from 'styled-components'
import Image from '../Image'
import Highlight from './Highlight'
import Carousel from '../Carousel'

function ImagesHighlight( {
  title, description, eyebrow, images, alignment,
} ) {
  return (
    <Highlight
      { ...{
        title, description, eyebrow, alignment,
      } }
      image={ images && (
        <StyledCarousel>
          <Carousel overlayControls dots>
            {images.map( image => (
              <li key={ image.id }>
                <StyledImage>
                  <Image
                    key={ image.id }
                    src={ image.url }
                    alt={ image.alt }
                    dateModified={ image.dateModified }
                    layout="fill"
                  />
                </StyledImage>
              </li>
            ) )}
          </Carousel>
        </StyledCarousel>
      ) }
    />
  )
}

const StyledCarousel = styled.div`
  overflow: hidden;
`

const StyledImage = styled.div`
  position: relative;
  aspect-ratio: 1.59/1;

  img {
    object-fit: cover;
  }
`

export default ImagesHighlight
