import { Fragment } from 'react'
import { renderToStaticMarkup } from 'react-dom/server'

export const slugify = ( str: string ) => {
  let result = str
  result = result.replace( /^\s+|\s+$/g, '' ) // trim leading/trailing white space
  result = result.toLowerCase() // convert string to lowercase
  result = result.replace( /[^a-z0-9 -]/g, '' ) // remove any non-alphanumeric characters
    .replace( /\s+/g, '-' ) // replace spaces with hyphens
    .replace( /-+/g, '-' ) // remove consecutive hyphens
  return result
}

export const nl2p = str => (
  <>
    { str.split( /(?:\r\n\r\n|\r\r|\n\n)/g ).map( ( line, i ) => (
      // eslint-disable-next-line react/no-array-index-key
      <Fragment key={ i }>
        <p
          // eslint-disable-next-line react/no-array-index-key
          key={ i }
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={ { __html: line } }
        />
      </Fragment>
    ) ) }
  </>
)

/**
 * Note: whereas nl2p returns a JSX object, nl2pIfNotRedactored returns markup as 
 * a string because it’s intended for use with dangerouslySetInnerHTML
 */
export const nl2pIfNotRedactored = str => {
  if ( str.startsWith( '<p>' ) ) {
    return str
  }

  return renderToStaticMarkup( nl2p( str ) )
}

export const nl2br = str => (
  <>
    { str.split( /(?:\r\n|\r|\n)/g ).map( ( line, i ) => (
      // eslint-disable-next-line react/no-array-index-key
      <Fragment key={ i }>{ line }<br /></Fragment>
    ) ) }
  </>
)

export const humanList = jsxEls => {
  if ( jsxEls.length < 2 ) {
    return jsxEls
  }

  const separated = jsxEls.reduce( ( r, a, i ) => {
    if ( i === jsxEls.length - 1 ) {
      return r.concat( a )
    }

    if ( i === jsxEls.length - 2 ) {
      return r.concat( a, ' and ' )
    }

    return r.concat( a, ', ' )
  }, [] )

  return separated
}

export function wrapWithPTags( str ) {
  return `
    ${ !str.startsWith( '<p' ) ? '<p>' : '' }
    ${ str }
    ${ !str.endsWith( '</p>' ) ? '</p>' : '' }
  `
}
