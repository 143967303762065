import Stack from '../layout/Stack'
import Container from '../layout/Container'
import PageSegmentBackground from './PageSegmentBackground'
import PageSegmentHeading from './PageSegmentHeading'
import Experts from '../experts/Experts'

function ExpertsSegment( {
  anchorId,
  title,
  subtitle,
  experts,
  additionalBottomPadding,
} ) {
  return (
    <PageSegmentBackground 
      anchorId={ anchorId }
      color="green100"
      segmentType="experts"
      hasSectionTitle={ title || subtitle }
      additionalBottomPadding={ additionalBottomPadding }
    >  
      <Container>
        <Stack
          $top={ 12 }
          $bottom={ 12 }
          $between={ 10 }
        >
          <PageSegmentHeading
            title={ title }
            subtitle={ subtitle }
          />
          <Experts
            experts={ experts }
          />
        </Stack>
      </Container>
    </PageSegmentBackground>
  )
}

export default ExpertsSegment
