import {
  Key, ReactElement, JSXElementConstructor, ReactFragment, 
} from 'react'
import styled from 'styled-components'
import Button, { ButtonColors } from './Button'

type TButton = { 
  preIcon?: string; 
  postIcon?: string; 
  href: string; 
  openInNewTab?: boolean;
  id: Key; 
  color?: ButtonColors; 
  primary?: boolean; 
  label: 
    string 
    | number 
    | boolean 
    | ReactElement<any, string | JSXElementConstructor<any>> 
    | ReactFragment; 
  onClick?: any 
}

function ButtonGroup( { buttons }: { buttons: Array<TButton> } ) {
  return (
    <StyledButtonGroup>
      { buttons.map( ( button: TButton ) => {
        const preIcon = button.preIcon || null
        const postIcon = button.postIcon || null

        if ( button.href ) {
          return (
            <Button
              key={ button.id }
              href={ button.href }
              target={ button.openInNewTab ? '_blank' : '_self' }
              color={ button.color || 'green' }
              outline={ !button.primary }
            >
              <>
                { preIcon }
                <span>{ button.label }</span>
                { postIcon }
              </>
            </Button>
          )
        }
        
        if ( button.onClick ) {
          return (
            <Button
              key={ button.id }
              onClick={ button.onClick }
              color={ button.color || 'green' }
              outline={ !button.primary }
            >
              <>
                { preIcon }
                <span>{ button.label }</span>
                { postIcon }
              </>
            </Button>
          )
        }

        return null
      } ) }
    </StyledButtonGroup>
  )
}

export const StyledButtonGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: var( --grid-gutter );
`

export default ButtonGroup
