import styled from 'styled-components'
import Image from '../Image'
import { wrapWithPTags } from '../../lib/strings'
import { textLink } from '../../styles/links'

function InfoPoint( {
  title,
  description,
  icon,
  alignment,
} ) {
  return (
    <StyledInfoPoint $alignment={ alignment }>
      { icon && (
        <StyledIcon $alignment={ alignment }>
          <Image
            src={ icon.url }
            alt={ icon.alt }
            width={ icon.width }
            height={ icon.height }
            dateModified={ icon.dateModified }
          />
        </StyledIcon>
      ) }
      { title && <h3>{ title }</h3> }
      { description && (
        <div
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={ { __html: wrapWithPTags( description ) } }
        />
      ) }
    </StyledInfoPoint>
  )
}

const StyledInfoPoint = styled.div<{ $alignment: 'left' | 'center' }>`
  text-align: ${ p => p.$alignment };
  word-wrap: break-word;
    word-break: break-all;
    word-break: break-word;
    hyphens: auto;

  h3 {
    margin: 0;
    ${ p => p.theme.typo.semiBold }
    font-size: ${ p => p.theme.typo.sizes.large };
    line-height: 1.2;

    @media ( max-width: 500px ) {
      font-size: ${ p => p.theme.typo.sizes.medium };
    }
  }

  p {
    margin: 0;
  }

  h3 + div > p {
    margin-top: calc( var( --stack-basis ) * 3 );
  }

  a {
    ${ p => textLink( p.theme ) }
  }

  strong {
    font-weight: 600;
  }
`

const StyledIcon = styled.div<{ $alignment: 'center' | 'left' }>`
  width: 120px;
  margin: 0 ${ p => p.$alignment === 'center' ? 'auto' : 0 };
  margin-bottom: calc( var( --stack-basis ) * 4 );
`

export default InfoPoint
