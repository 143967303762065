import styled, { css } from 'styled-components'
import LongArrowIcon from '../icons/LongArrowIcon'

function ProcessStep( {
  text1,
  text2,
  color,
  highlight,
  breakpoint,
} ) {
  const colors = {
    green: 'green400',
    blue: 'blue400',
    lightBlue: 'blue300',
  }

  return (
    <StyledProcessStep 
      $color={ colors[ color ] }
      $highlight={ highlight }
      $breakpoint={ breakpoint }
    >
      <div>
        { text1 && <h3>{ text1 }</h3> }
        { text2 && <p>{ text2 }</p> }
      </div>
      <LongArrowIcon />
    </StyledProcessStep>
  )
}

const StyledProcessStep = styled.li<{
  $color: 'green400' | 'blue400' | 'blue300',
  $highlight: boolean,
  $breakpoint: string,
}>`
  display: flex;
  align-items: stretch;
  gap: 1rem;
  
  > div {
    display: flex;
    flex-direction: column;
    width: max( 250px, min( 22vw, 320px ) );
    background: ${ p => p.theme.colors.realWhite };
    border: 1px solid ${ p => p.theme.getColor( p.$color ) };
    border-radius: var( --card-border-radius );
    overflow: hidden;
    text-align: center;

    h3,
    p {
      margin: 0;
    }

    h3 {
      flex-grow: 1;
      padding: calc( 3 * var( --stack-basis ) ) 1rem;
      ${ p => p.theme.typo.regular };
      font-size: ${ p => p.theme.typo.sizes.normal };

      ${ p => p.$highlight && css`
        font-weight: 600;
      ` }
    }

    p {
      padding: calc( 2 * var( --stack-basis ) ) 1rem;
      background: ${ p => p.theme.getColor( p.$color ) };
      color: ${ p => p.theme.colors.realWhite };
      ${ p => p.theme.typo.semiBold };
      font-size: ${ p => p.theme.typo.sizes.large };
    }
  }

  > svg {
    align-self: center;
  }

  @media ( max-width: ${ p => p.$breakpoint } ) {
    flex-direction: column;
    width: 100%;
    max-width: 300px;

    > div {
      width: 100%;
    }

    > svg {
      transform: rotate( 90deg );
    }
  }
`

export default ProcessStep
