import { useRouter } from 'next/router'
import { useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import useModalTraits from '../hooks/useModalTraits'
import Button from './Button'
import ChevronIcon from './icons/ChevronIcon'

const localeMap = {
  de: 'Deutsch',
  en: 'English',
  fr: 'Français',
  it: 'Italiano',
  pt: 'Português',
}

const activeLocales = ['de', 'fr', 'en', 'it']

function LanguageSwitcher() {
  const containerRef = useRef()

  const {
    locale, 
    pathname, 
    asPath, 
    query, 
    push, 
  } = useRouter()

  const [isOpen, setIsOpen] = useState( false )
  const toggleSwitcher = () => setIsOpen( s => !s )
  
  const switchLocale = newLocale => {
    setIsOpen( false )
    
    if ( newLocale !== locale ) {
      push( { pathname, query }, asPath, { locale: newLocale } )
    }
  }

  useModalTraits( {
    isOpen,
    containerRef,
    close: () => setIsOpen( false ),
  } )

  return (
    <StyledLanguageSwitcher ref={ containerRef }>
      <Button
        outline
        color="green"
        onClick={ toggleSwitcher }
      >
        { localeMap[ locale ] }
        <ChevronIcon />
      </Button>
      <StyledDropdown $isOpen={ isOpen }>
        { activeLocales.map( l => (
          <StyledDropdownItem 
            key={ l }
            $isActive={ l === locale }
          >
            <button
              type="button"
              onClick={ () => switchLocale( l ) }
            >
              { localeMap[ l ] }
            </button>
          </StyledDropdownItem>
        ) ) }
      </StyledDropdown>
    </StyledLanguageSwitcher>
  )
}

const StyledLanguageSwitcher = styled.div`
  position: relative;
`

const StyledDropdown = styled.div<{
  $isOpen: boolean,
}>`
  position: absolute;
  right: 0;
  top: 3rem;
  min-width: 100%;
  margin-bottom: 2rem;
  border-radius: var( --card-border-radius );;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.12);
  opacity: 0;
  pointer-events: none;
  transition: opacity .3s ease-out;

  ${ p => p.$isOpen && css`
    opacity: 1;
    pointer-events: all;
  ` }
`

const StyledDropdownItem = styled.div<{
  $isActive: boolean,
}>`
  a,
  button {
    display: block;
    width: 100%;
    padding: 10px 12px;
    border: 0;
    background: ${ p => p.theme.colors.realWhite };
    font-size: ${ p => p.theme.typo.sizes.small };
    text-align: left;
    text-decoration: none;
    color: inherit;
    transition: background .3s ease-out;

    &:hover {
      background: ${ p => p.theme.colors.green100 };
    }
  }
`

export default LanguageSwitcher
