import { useState } from 'react'
import styled from 'styled-components'
import Stack from './layout/Stack'
import Background from './layout/Background'
import Container from './layout/Container'
import Image from './Image'
import Button from './Button'
import VideoPlayIcon from './icons/VideoPlayIcon'
import VideoEmbed from './VideoEmbed'

function VideoCta( {
  anchorId = null,
  title,
  description,
  coverImage,
  buttonLabel,
  videoUrl,
} ) {
  const doVideo = () => {
    setShowVideo( true )
  }

  const [showVideo, setShowVideo] = useState( false )
  
  return (
    <StyledVideoCta id={ anchorId }>
      <Background $color="blue100">
        <Container $containerType="full">
          <Container>
            <StyledGrid>
              <StyledCoverImage>
                <button
                  type="button"
                  onClick={ doVideo }
                >
                  <Image
                    src={ coverImage.url }
                    alt={ coverImage.alt }
                    dateModified={ coverImage.dateModified }
                    layout="fill"
                    objectFit="cover"
                  />
                  <VideoPlayIcon />
                </button>
                { showVideo && videoUrl && (
                  <VideoEmbed 
                    url={ videoUrl } 
                    autoplay
                  />
                ) }
              </StyledCoverImage>
              <StyledContent>
                <Stack
                  $top={ 4 }
                  $between={ 4 }
                >
                  <h2>{ title }</h2>
                  { description && <p>{ description }</p> }
                  { buttonLabel && (
                    <Button
                      outline
                      onClick={ doVideo }
                    >
                      { buttonLabel }
                    </Button>
                  ) }
                </Stack>
              </StyledContent>
            </StyledGrid>
          </Container>
        </Container>
      </Background>
    </StyledVideoCta>
  )
}

export const StyledVideoCta = styled.div`
  position: relative;
`

const StyledCoverImage = styled.div`
  position: relative;
  flex: 0 0 50%;
  aspect-ratio: 1.59/1;
  margin-top: max( var( --grid-gutter ), calc( 4 * var( --stack-basis ) ) );

  button {
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border: 0;
    padding: 0;
    margin: 0;
    background: ${ p => p.theme.colors.black };
    cursor: pointer;

    img {
      opacity: 0.6;
    }

    &:hover svg {
      transform: 
      translate( -50%, -50% )
      scale( 1.1 );
    }
  }

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate( -50%, -50% );
    transition: transform .3s ease-out;
  }
`

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: max( var( --grid-gutter ), calc( 4 * var( --stack-basis ) ) );

  h2 {
    ${ p => p.theme.typo.semiBold }
    font-size: ${ p => p.theme.typo.sizes.veryLarge };
    line-height: 1.2;

    @media ( max-width: 500px ) {
      font-size: ${ p => p.theme.typo.sizes.medium };
    }
  }
`

const StyledGrid = styled.div`
  display: flex;
  flex-direction: column;

  @media ( min-width: 900px ) {
    flex-direction: row;
    
    ${ StyledContent } {
      flex: 0 0 calc( 50% - ( var( --grid-gutter ) * 3 ) );
      margin-left: auto;
    }

    ${ StyledCoverImage } {
      margin-top: calc( -6 * var( --stack-basis ) );
      margin-bottom: calc( -6 * var( --stack-basis ) );
    }
  }
`

export default VideoCta
