import { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import styled, { css } from 'styled-components'
import useSwipe from '@/hooks/useSwipe'
import ChevronIcon from './icons/ChevronIcon'

export default function Carousel( { children, overlayControls = false, dots = false } ) {
  const slidesCount = children.length
  const [currentSlide, setCurrentSlide] = useState( 0 )
  
  const useSwipeProps = useSwipe( {
    left: () => next(),
    right: () => previous(),
  } )

  const previous = () => {
    if ( currentSlide > 0 ) {
      setCurrentSlide( currentSlide - 1 )
    }
  }

  const next = () => {
    if ( currentSlide < slidesCount - 1 ) {
      setCurrentSlide( currentSlide + 1 )
    }
  }

  return (
    <StyledCarousel>
      <StyledControls $overlay={ overlayControls }>
        <button
          type="button"
          onClick={ previous }
        >
          <span>
            <FormattedMessage
              defaultMessage="Previous property"
              id="ev8tkE"
              description="Carousel control: Previous property"
            />
          </span>
          <ChevronIcon />
        </button>
        <button
          type="button"
          onClick={ next }
        >
          <span>
            <FormattedMessage
              defaultMessage="Next property"
              id="HTUOPV"
              description="Carousel control: Next property"
            />
          </span>
          <ChevronIcon />
        </button>
      </StyledControls>
      <StyledSlider
        $currentSlide={ currentSlide }
        $totalSlides={ slidesCount }
        // eslint-disable-next-line react/jsx-props-no-spreading
        { ...useSwipeProps }
      >
        { children }
      </StyledSlider>
      {dots && (
        <StyledDots>
          {new Array( slidesCount ).fill( true ).map( ( _, i ) => (
            // eslint-disable-next-line react/no-array-index-key
            <li key={ i }>
              <button type="button" className={ i === currentSlide ? 'is-current' : '' } onClick={ () => setCurrentSlide( i ) }>
                {i}
              </button>
            </li>
          ) )}
        </StyledDots>
      )}
    </StyledCarousel>
  )
}

const StyledCarousel = styled.div`
  position: relative;
  z-index: 0;
`

const StyledControls = styled.div<{ $overlay: boolean }>`
  display: flex;
  justify-content: center;
  gap: var( --stack-basis );
  margin-bottom: calc( 3 * var( --stack-basis ) );

  ${ p => p.$overlay && css`
    position: absolute;
    inset: 0;
    z-index: 1;
    align-items: center;
    justify-content: space-between;
    padding: 0 2rem;
  ` }

  button {
    -webkit-appearance: none;
    appearance: none;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    border: 1px solid ${ ( { theme } ) => theme.colors.grey300 };
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      overflow: hidden;
      width: 0;
    }

    svg {
      width: 16px;
      height: 16px;
      
      path {
        fill: ${ ( { theme } ) => theme.colors.grey700 };
      }
    }

    &:first-child > svg{
      transform: rotate( 90deg );
    }
      
    &:last-child > svg {
      transform: rotate( 270deg );
    }
  }
`

const StyledSlider = styled.ul<{
  $currentSlide: number;
  $totalSlides: number;
}>`
  --carousel-slides-gutter: calc( 3 * var( --stack-basis ) );
  position: relative;
  width: 100%;
  display: flex;
  overflow-x: visible;
  gap: var( --carousel-slides-gutter );
  list-style: none;
  margin: 0;
  padding: 0;
  left: calc(
    ( 100% + var( --carousel-slides-gutter ) ) * ${ ( { $currentSlide } ) => $currentSlide * -1 }
  );
  transition: left 0.5s ease-in-out;

  > li {
    position: relative;
    flex: 0 0 100%;
    width: 100%;
    display: flex;
    transition: opacity 0.5s linear;

    &:not( :nth-child( ${ ( { $currentSlide } ) => $currentSlide + 1 } ) ) {
      opacity: 0.5;
    }

    > * {
      width: 100%;
    }
  }
`

const StyledDots = styled.ol`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  list-style: none;

  button {
    width: 8px;
    height: 8px;
    margin: 0;
    padding: 0;
    border-radius: 50%;
    overflow: hidden;
    text-indent: -999em;
    border: none;
    appearance: none;
    background-color: ${ p => p.theme.colors.grey300 };
    transition: background-color 0.4s ease;
    &.is-current {
      background-color: ${ p => p.theme.colors.green400 };
    }
  }
`
