import styled from 'styled-components'
import InfoPoint from './InfoPoint'

function InfoPoints( { infoPoints, alignment } ) {
  return (
    <StyledInfoPoints $alignment={ alignment }>
      <div>
        { infoPoints.map( infoPoint => (
          <InfoPoint
            key={ infoPoint.id }
            title={ infoPoint.infoPointTitle }
            description={ infoPoint.infoPointDescription }
            icon={ infoPoint.infoPointIcon[ 0 ] }
            alignment={ alignment }
          />
        ) ) }
      </div>
    </StyledInfoPoints>
  )
}

const StyledInfoPoints = styled.div<{ $alignment: 'left' | 'center' }>`
  --info-point-stack-padding: max( var( --grid-gutter ), calc( 3 * var( --stack-basis ) ) );
  overflow: hidden;

  > div {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 
      calc( -1 * var( --info-point-stack-padding ) ) 
      calc( -1 * var( --grid-gutter ) );
    
    > * {
      box-sizing: border-box;
      flex: 1 0 calc( 33.333% - ( 2 * var( --grid-gutter ) ) );
      min-width: min( 205px, 90% );
      max-width: ${ p => p.$alignment === 'center' ? '360px' : 'none' };
      margin: 
        var( --info-point-stack-padding )
        calc( 1 * var( --grid-gutter ) );
    }
  }
`

export default InfoPoints
