import Stack from '../layout/Stack'
import Container from '../layout/Container'
import PageSegmentBackground from './PageSegmentBackground'
import PageSegmentHeading from './PageSegmentHeading'
import PageSegmentBodyText from './PageSegmentBodyText'
import ProcessSteps from '../processSteps/ProcessSteps'

function ProcessStepsSegment( {
  anchorId,
  title,
  subtitle,
  bodyText,
  processSteps,
  additionalBottomPadding,
} ) {
  return (
    <PageSegmentBackground 
      anchorId={ anchorId }
      color="ivory"
      segmentType="processSteps"
      hasSectionTitle={ title || subtitle }
      additionalBottomPadding={ additionalBottomPadding }
    >
      <Container>
        <Stack
          $top={ 12 }
          $bottom={ 12 }
          $between={ 10 }
        >
          <PageSegmentHeading
            title={ title }
            subtitle={ subtitle }
          />
          <ProcessSteps
            processSteps={ processSteps }
          />
          <PageSegmentBodyText
            html={ bodyText }
            bodyStyle="small"
          />
        </Stack>
      </Container>
    </PageSegmentBackground>
  )
}

export default ProcessStepsSegment
