import styled from 'styled-components'
import Package from './Package'

function Packages( { packages } ) {
  return (
    <StyledPackages>
      { packages.map( thePackage => (
        <Package
          key={ thePackage.id }
          thePackage={ 
            thePackage.packageTitle 
              ? convertPackagesFieldToPacakgesEntry( thePackage )
              : thePackage 
          }
        />
      ) )}
    </StyledPackages>
  )
}

const StyledPackages = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: calc( var( --grid-gutter ) * 2 );
`

function convertPackagesFieldToPacakgesEntry( packageFieldValues ) {
  return {
    ...packageFieldValues,
    packageName: packageFieldValues.packageTitle,
    packageDescription: packageFieldValues.description,
    bulletPoints: packageFieldValues.bullets,
    packageAdditional: packageFieldValues.additional.map( pa => ( {
      ...pa,
      partnerLogo: pa.logo,
    } ) ),
  }
}

export default Packages
