import Stack from '../layout/Stack'
import PageSegmentHeading from './PageSegmentHeading'
import Container from '../layout/Container'
import PageSegmentBackground from './PageSegmentBackground'
import PropertyListingsLarge from '../propertyListingsLarge/PropertyListingsLarge'
import { useCraft } from '../../lib/craft'

export default function PropertyListingsLargeSegment( {
  anchorId,
  title,
  subtitle,
  additionalBottomPadding,
} ) {
  const craftData = useCraft()
  const companyStub = craftData?.pageProps?.partnerSlug

  return (
    <PageSegmentBackground 
      anchorId={ anchorId }
      segmentType="propertyListingsLarge"
      hasSectionTitle={ title || subtitle }
      additionalBottomPadding={ additionalBottomPadding }
      color="ivory"
      hideOverflow
    >
      <Container>
        <Stack
          $top={ 12 }
          $bottom={ 12 }
          $between={ 10 }
        >
          <PageSegmentHeading
            title={ title }
            subtitle={ subtitle }
          />
          <PropertyListingsLarge 
            companyStub={ companyStub }
            related
          />
        </Stack>
      </Container>
    </PageSegmentBackground>
  )
}
