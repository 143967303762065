import {
  useEffect, useState, useRef, 
} from 'react'
import styled, { css } from 'styled-components'
import { wrapWithPTags } from '../lib/strings'
import { textLink } from '../styles/links'
import ArrowIcon from './icons/ArrowIcon'

function Accordion( { 
  items, 
  openFirstItem = true,
  alwaysOne = true,
  theme = null,
} ) {
  const [openItem, setOpenItem] = useState( openFirstItem || alwaysOne ? items[ 0 ]?.id : null )

  const toggleItem = itemId => {
    setOpenItem( openItem !== itemId || alwaysOne ? itemId : null )
  }
  
  return (
    <StyledAccordion>
      { items.map( item => (
        <AccordionItem
          key={ item.id }
          theme={ theme }
          item={ item }
          isOpen={ item.id === openItem }
          toggleItem={ toggleItem }
        />
      ) ) }
    </StyledAccordion>
  )
}

function AccordionItem( { 
  theme,
  item,
  isOpen, 
  toggleItem,
} ) {
  const headerRef = useRef( null )
  const bodyRef = useRef( null )
  const [bodyHeight, setBodyHeight] = useState( null )

  useEffect( () => {
    if ( bodyRef.current ) {
      setBodyHeight( 
        Math.max( bodyRef.current.offsetHeight, bodyRef.current.scrollHeight ), 
      )
    }
  }, [isOpen] )

  useEffect( () => {
    // A hack to get the correct height for an item that is open on initial render
    // ¯\_(ツ)_/¯
    if ( isOpen && bodyRef.current ) {
      requestAnimationFrame( () => {
        requestAnimationFrame( () => {
          requestAnimationFrame( () => {
            if ( bodyRef.current ) {
              setBodyHeight( 
                Math.max( bodyRef.current.offsetHeight, bodyRef.current.scrollHeight ), 
              )
            }
          } )
        } )
      } )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [] )

  return (
    <StyledItem
      $isOpen={ isOpen }
      $height={ bodyHeight }
      $theme={ theme }
    >
      <h3 ref={ headerRef }>
        <button
          type="button"
          onClick={ () => toggleItem( item.id ) }
        >
          <StyledArrow>
            <ArrowIcon />
          </StyledArrow>
          { item.heading }
        </button>
      </h3>
      <StyledBody 
        ref={ bodyRef }
        dangerouslySetInnerHTML={ { __html: wrapWithPTags( item.itemContent ) } }
      />
    </StyledItem>
  )
}

const StyledBody = styled.div``

const StyledArrow = styled.div``

const StyledItem = styled.li<{
  $isOpen: boolean,
  $height: number | null,
  $theme?: 'blue' | 'green',
}>`
  --arrow-size: 33px;
  max-width: 650px;

  h3 {
    margin: 0 0 calc( 3 * var( --stack-basis ) );

    button {
      display: flex;
      align-items: flex-start;
      padding: 0;
      margin: 0;
      background: 0;
      border: 0;
      ${ p => p.theme.typo.semiBold }
      font-size: ${ p => p.theme.typo.sizes.large };
      line-height: 1.2;
      text-align: left;
      cursor: pointer;
      transition: color .3s ease-out;

      ${ StyledArrow } {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 0 0 var( --arrow-size );
        width: var( --arrow-size );
        height: var( --arrow-size );
        margin-right: var( --grid-gutter );
        transition: transform .3s ease-in-out;

        svg {
          width: 100%;
        }

        ${ p => p.$isOpen && css`
          transform: rotate( 90deg );
        ` }
      }

      &:hover {
        color: ${ p => p.theme.colors.green400 };
      }

      @media ( max-width: 500px ) {
        font-size: ${ p => p.theme.typo.sizes.medium };
      }
    }
  }

  ${ StyledBody } {
    overflow: hidden;
    height: 0;
    margin-left: calc( var( --grid-gutter ) + var( --arrow-size ) );
    font-size: ${ p => p.theme.typo.sizes.dropped };
    transition: height .3s ease-in-out;

    ${ p => p.$isOpen && css`
      height: ${ p.$height ? `${ p.$height }px` : 'auto' };
    ` }

    p {
      &:first-child {
        margin-top: 0;
      }
      
      &:last-child {
        margin-bottom: 0;
        padding-bottom: calc( 3 * var( --stack-basis ) );
      }
    }

    strong {
      font-weight: 600;
    }

    a {
      ${ p => textLink( p.theme ) }
    }
  }

  ${ p => p.$theme === 'blue' && css`
    h3 button {
      color: ${ p.theme.colors.blue700 };

      ${ StyledArrow } svg path {
        fill: ${ p.theme.colors.blue700 };
      }

      ${ p.$isOpen && css`
        color: ${ p.theme.colors.realWhite };
        
        ${ StyledArrow } svg path {
          fill: ${ p.theme.colors.realWhite };
        }
      ` }

      &:hover {
        color: ${ p.theme.colors.white };
      }
    }
  ` }

  ${ p => p.$theme === 'green' && css`
    color: ${ p.theme.colors.green100 };
    
    h3 button {
      color: inherit;
      
      ${ StyledArrow } svg path {
        fill: ${ p.theme.colors.green100 };
      }

      ${ p.$isOpen && css`
        color: ${ p.theme.colors.realWhite };
        
        ${ StyledArrow } svg path {
          fill: ${ p.theme.colors.realWhite };
        }
      ` }

      &:hover {
        color: ${ p.theme.colors.realWhite };
      }
    }
  ` }
`

const StyledAccordion = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`

export default Accordion
