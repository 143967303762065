import styled from 'styled-components'
import Image from '../Image'

function Expert( { expert } ) {
  const image = expert.mainImage[ 0 ]

  return (
    <StyledExpert>
      <StyledImage>
        <Image
          src={ image.url }
          alt={ image.alt }
          dateModified={ image.dateModified }
          layout="fill"
          objectFit="cover"
        />
      </StyledImage>
      <h4>{ expert.title }</h4>
    </StyledExpert>
  )
}

const StyledExpert = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  h4 {
    margin: 0;
    ${ p => p.theme.typo.semiBold };
    font-size: ${ p => p.theme.typo.sizes.normal };
  }
`

const StyledImage = styled.div`
  overflow: hidden;
  position: relative;
  width: 150px;
  height: 150px;
  border-radius: 100%;
  margin-bottom: calc( 3 * var( --stack-basis ) );
`

export default Expert
