import styled from 'styled-components'
import ContactCard from './ContactCard'

function ContactCards( { contactCards } ) {
  return (
    <StyledContactCards>
      { contactCards.map( contactCard => (
        <ContactCard
          key={ contactCard.id }
          contactCard={ contactCard }
        />
      ) ) }
    </StyledContactCards>
  )
}

const StyledContactCards = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  gap: calc( 2 * var( --grid-gutter ) );

  > * {
    max-width: 305px;
    min-width: min( 250px, 100% );
    flex: 0 0 25%;
  }
`

export default ContactCards
